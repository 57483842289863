.MetaverseProjectContainer{
    position: relative;
    padding: 50px 0px;
    background: radial-gradient(#DBB7DC,#B194C9, #6F6EAC);
}
.metaverseProjectBackground{
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.metaverseProjectElements{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    position: relative;
    width: 100%;
    height: 100%;
}
.metaverseProjectHeader{
    width: 100%;
    height: 73px;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 100px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}
.metaverseProjectSpan{
    width: 60%;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    color: #000000;
}
.metaverseProjectElement{
    width: 60%;
    height: 60vh;
}
.mobile .metaverseProjectElement{
    width: 90%;
    /* margin: auto; */
    height: 300px;

}
.tablet .metaverseProjectElement{
    width: 80%;
}


/* Reeponsive Tablet Settings */

.tablet .MetaverseProjectContainer{
    height: 80vh;
}

.tablet .metaverseProjectElements{
    gap: 30px;
}



.tablet .metaverseProjectSpan{
    height: 100px;
}

/* Reeponsive Mobile Settings */

.mobile .metaverseProjectSpan{
    font-size: 15px;
    line-height: 20px;
    width: 90%;
}

.mobile .metaverseProjectHeader{
    font-size: 30px;
    line-height: 35px;
}


