.cloothContainer{
    display: flex;
    justify-content: center;
    background: #EBEBEB;
    margin-top: 60px;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.cloothImageDiv{
    z-index: 1;
    height: 100%;
    display: flex;
}


/* Reeponsive Tablet Settings */

.tablet .cloothContainer{
    flex-direction: column;
}

.tablet .cloothImageDiv{
    height: 50%;
}

/* Reeponsive Mobile Settings */

.mobile .cloothContainer{
    padding: 30px 0px;
    flex-direction: column;
}

.mobile .cloothImageDiv{
    height: 50%;
}
