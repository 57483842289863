.arDetailsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    position: relative;
}
.arDetailsSpans{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    padding-bottom: 5px;
}
.arDetailsHeader{
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    width: 65%;
}
.arDetailsSpan{
    width: 80%;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    width: 65%;
    color: #000000;
}

.arDetailsOptions{
    display: flex;
    gap: 50px;
}
.option1Span{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #ED401A;
    mix-blend-mode: normal;
    cursor: pointer;
}
.option2Span{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #ABABAB;
    mix-blend-mode: normal;
    cursor: pointer;
}
.option3Span{
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #ABABAB;
    mix-blend-mode: normal;
    cursor: pointer;
}
.option1Line{
    width: 308.53px;
    height: 0px;
    border: 3px solid #ED401A;
    transform: rotate(-180deg);
}
.option2Line{
    width: 308.53px;
    height: 0px;
    border: 3px solid #ABABAB;
    transform: rotate(-180deg);
}
.option3Line{
    width: 308.53px;
    height: 0px;
    border: 3px solid #ABABAB;
    transform: rotate(-180deg);
}
.option1Popup{
    position: absolute;
    width: 307px;
    height: 195px;
    left: 200px;
    top: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.35);
}
.option2Popup{
    position: absolute;
    width: 307px;
    height: 195px;
    left: 565px;
    top: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.35);
}
.option3Popup{
    position: absolute;
    width: 307px;
    height: 195px;
    left: 934px;
    top: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.35);
}
.optionPopupSpan{
    width: 250px;
    height: 169px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
    position: relative;
    top: 10px;
}
.arDetailsModelViewer{
    width: 65%;
    height: 70%;
    border: 1px solid;
    margin-top: 4vh;
}

/* Reeponsive Tablet Settings */

.tablet .arDetailsHeader{
    font-size: 29px;
}
.tablet .arDetailsSpan{
    font-size: 13px;
}
.tablet .showAtHomeImg{
    display: none;
}

.tablet .modelViewerShadow{
    bottom: 71px;
}

.tablet .arDetailsBubble{
    display: none;
}

/* Reeponsive Mobile Settings */
.mobile .modelViewerShadow{
    width: 90% !important;
}
.mobile .arDetailsHeader{
    font-size: 29px;
    width: 92%;
}
.mobile .arDetailsSpan{
    font-size: 13px;
    width: 90%;
}
.mobile .showAtHomeImg{
    display: none;
}

.mobile .modelViewerShadow{
    bottom: 88px;
}

.mobile .arDetailsBubble{
    display: none;
}

.mobile .arDetailsModelViewer{
    width: 90%!important;
    height: 60%;
}

.sugar-model-viewer-container{
    height: 80vh;
}