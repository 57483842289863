.watchContainer{
    padding: 50px 0px 0px 0px;
    display: flex;
    justify-content: space-evenly;
    background: #EBEBEB;
    margin-top: 60px;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.watchImage{
    width: 100%;
}

.watchImageDiv{
    z-index: 1;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

/* Reeponsive Tablet Settings */

.tablet .watchContainer{
    flex-direction: column;
}

/* Reeponsive Mobile Settings */

.mobile .watchContainer{
    flex-direction: column;
}

.mobile .watchImageDiv{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
}
