.createYourProductContainer{
    height: 100vh;
    background: #EFEFEF;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.corner1{
    position: absolute;
    left: 50px;
    top: 50px;
}
.corner2{
    position: absolute;
    right: 50px;
    top: 50px;
}
.corner3{
    position: absolute;
    bottom: 50px;
    left: 50px;
}
.corner4{
    position: absolute;
    bottom: 50px;
    right: 50px;
}
.sugarModelViewer{
    width: 93%;
    height: 70%;
    position: absolute;
    bottom: 60px;
}
#sugar-joint-container{
    bottom: 0px !important;
    position: absolute;
}

/* Reeponsive Tablet Settings */

.tablet .sugarModelViewer{
    top: 200px;
    left: 59px;
}

/* Reeponsive Mobile Settings */

.mobile .sugarModelViewer{
    top: 22vh;
    width: 80%;
}

.mobile .corner1{
    left: 20px;
}

.mobile .corner2{
    right: 20px;
}

.mobile .corner3{
    left: 20px;
}

.mobile .corner4{
    right: 20px;
}

@media screen and (max-width: 1200px) {
    .sugarModelViewer{
        top: 200px;
    }
}