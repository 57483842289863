.mainSectoralSolutionContainer {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	gap: 50px;
	padding: 50px 0px;
}

.popup {
	display: none;
	width: 90%;
	display: flex;
	justify-content: space-evenly;
	position: fixed;
	top: 15%;
	align-items: center;
	background: #efefef;
	border-radius: 12px;
	gap: 30px;
	z-index: 9;
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
	padding: 50px 0px;
	left: 50%;
	transform: translate(-50%);
}

.solutionMainImage {
	width: 100%;
}

.solutionMainImage.selected {
	border: 1px solid #ed401a;
	border-radius: 20px;
}

.popupCloseButtons {
	display: flex;
	width: 100%;
	justify-content: flex-end;
	padding: 10px 10px;
	position: absolute;
	top: 0px;
	z-index: 1;
}

.popupCloseButton {
	margin-right: 10px;
	cursor: pointer;
}

.popupImageDiv {
	width: 30%;
}

.solutionGroup {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	width: 100%;
	justify-content: center;
}

.mainSolutionHeaderLine {
	width: 26%;
}

.popupSpans {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 50%;
	gap: 50px;
}

.popupSpan {
	font-weight: 300;
	font-size: 17px;
	line-height: 26px;
	color: #000000;
}

.popupSpanElements {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	width: 80%;
	gap: 20px;
}

.mainSolutionHeader {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

#main-solutions-content-container {
    width: 80%;
    display: flex;
    gap: 30px;
    flex-direction: column;
}

.solutionImage {
	width: 50%;
	cursor: pointer;
}

.mainSolutionHeader h1 {
	font-style: normal;
	font-weight: 700;
	font-size: 38px;
	line-height: 52px;
	text-align: center;
	text-transform: capitalize;
}

.mainSolutionSpans {
	display: flex;
	justify-content: center;
	text-align: justify;
}

.mainSolutionSpan {
	padding: 0 30px;
}

.solutionItems {
	display: grid;
	flex-direction: column;
	position: relative;
	gap: 40px;
	grid-template-columns: repeat(4, 1fr);
	justify-content: center;
    justify-items: center;
}

.tablet .solutionItems {
	grid-template-columns: repeat(3, 1fr);
}

.mobile .solutionItems {
	grid-template-columns: repeat(2, 1fr);
}

.solutionItem {
    width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
    img {
        width: 100%;
    }
}

.solutionSpans {
	font-size: 16px;
	color: #000000;
}

.popupButton {
	width: 80%;
}

.popupOverlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
}

.mobile .solutionGroup {
	flex-wrap: wrap;
}

.tablet .mainSolutionHeaderLine {
	width: 30%;
}

.tablet .mainSolutionHeader h1 {
	font-size: 40px;
}
.mobile .mainSolutionHeaderLine {
	width: 20%;
	height: 2.5px;
	align-self: center;
	object-fit: cover;
}

.mobile .mainSolutionHeader h1 {
	font-size: 18px;
	line-height: 0;
}

.mobile .popup {
	flex-direction: column-reverse;
	height: auto;
	padding: 20px 10px;
	width: 90%;
}

.mobile .popupImageDiv {
	width: 65%;
}

.mobile .popupSpans {
	width: auto;
	padding: 0px 10px;
}

.mobile .popupSpanElements {
	margin-top: 20px;
	width: auto;
}
