.SugarGoalsContainer{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    gap: 50px;
}
.sugarGoalHeader{
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
}
.sugarGoalsElements1{
    position: absolute;
    display: flex;
    gap: 80px;
}
.sugarGoalsElements2{
    position: absolute;
    display: none;
    gap: 80px;
}
.sugarGoalsElement{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    position: relative;
    bottom: 30px;
}
.sugarGoalsHeader{
    height: 34px;
    width: 190px;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 66px;
    text-align: center;
}
.sugarGoalsSpan{
    height: 74px;
    width: 191px;
    font-style: normal;
    font-weight: 400;
    font-size: 13.5px;
    line-height: 19px;
    text-align: center;
    color: #8A8A8A;
    mix-blend-mode: normal;
}
.sugarGoalsImage{
    height: 33px;
    width: 33px;
}
.goalsBubble{
    position: absolute;
    right: 0px;
    z-index: -1;
}
.sugarGoalMobile{
    display: none;
   
}
.sugarGoalMobileRow1{
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 47%;
    top: 24%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sugarGoalMobileRow2{
    display: flex;
    flex-direction: row;
    position: absolute;
    width: 100%;
    height: 47%;
    left: 50%;
    top: 75%;
    transform: translate(-50%,-50%);
}
.sugarGoalMobile1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 10px;
}
.sugarGoalMobile2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 10px;

}
.sugarGoalMobile3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 10px;

}
.sugarGoalMobile4{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 10px;

}
.sugarGoalsImageMobile{
    width: 20%;
}
.sugarGoalsHeaderMobile{
    color: #895EE2;
    text-align: center;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -1px;
}
.sugarGoalsSpanMobile{
    color: #8A8A8A;
    text-align: center;
    font-family: Inter;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    width: 80%;
}
/* Reeponsive Tablet Settings */
.mobile .sugarGoalMobile{
    display: flex;
    background:url(/public/assets/img/sugarGoalElementMobile.svg);
    display: flex;
    width: 41%;
    width: 360px;
    height: 415px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
}
.tablet .sugarGoalMobile{
    display: flex;
    background:url(/public/assets/img/sugarGoalElementMobile.svg);
    background-repeat: no-repeat;
    display: flex;
    width: 360px;
    height: 415px;
    background-position: center;
    position: relative;
    background-size: cover;
}


@media screen and (max-width: 1200px){
    .sugarGoalMobile{
        display: flex;
        background:url(/public/assets/img/sugarGoalElementMobile.svg);
        background-repeat: no-repeat;
        display: flex;
        width: 360px;
        height: 415px;
        background-position: center;
        position: relative;
        background-size: cover;
    }
    .sugarGoals img{
        width: 200px;
    }
    .goals1v1{
        width: 230px !important;
        top: 200px;
        right: 100px;
    }
    .goals2v1{
        bottom: 30px;
        left: 130px;
    }
    .goals3v1{
        width: 230px !important;
        left: 100px;
        bottom: 40px;
    }
    .goals4v1{
        bottom: 270px;
        right: 100px;
    }
    .goals1,
    .goals2,
    .goals3,
    .goals4{
        display: none;
    }

    .goals1v1,
    .goals2v1,
    .goals3v1,
    .goals4v1{
        display: flex !important;
    }
    .sugarGoalsElements1{
        display: none;
    }
    .sugarGoalsElements2{
        display: none;
    }
    .sugarGoalsElementsGroup{
        display: flex;
        flex-direction: column;
        gap: 60px !important;
        position: relative;
        top: 30px;
        
    }
    .sugarGoalHeader{
        display: flex;
        font-size: 26px;
        position: relative;
        line-height: 40px;
        padding-top: 30px;
        text-align: center;
        justify-content: center;
    }
    .sugarGoalsHeader{
        width: 120px;
        font-size: 20px;
    }
    .sugarGoalsSpan{
        width: 148px;
        font-size: 10px;
    }
    .sugarGoalsImage{
        width: 23px;
        position: relative;
        top: 40px;
    }
    .sugarGoalsElements{
        top: 2240px;
    }
    .sugarGoals{
        position: relative;
        top: 50px;
    }
    .goalsBubble{
        display: none;
    }
    .goalsShadow{
        position: absolute;
        bottom: 10px;
        display: none;
    }
    .goalsShadowAbsolute{
        width: 100%;
        align-self: center;
        bottom: -30px;
        position: absolute;
        justify-self: center;
    }
    .goalsLine{
        width: 414px !important;
        position: relative;
        bottom: 162px;
    }
    .goalsShadow{
        width: 400px;
    }
    .sugarGoalHeaders{
        position: absolute;
        top: 10px;
    }
}

/* Reeponsive Mobile Settings */

@media screen and (max-width: 500px) {
    .SugarGoalsContainer{
        height: 68vh;
        justify-content: normal;
    }
    .sugarGoalsElements2{
        height: 100%;
        gap: 55px;
    }
    .sugarGoals{
        position: absolute;
        top: auto;
    }
    .sugarGoalsElementsGroup{
        justify-content: center;
    }
    .sugarGoalsElement{
        gap: 0px;
        bottom: 5px;
    }
    .sugarGoalsImage{
        top: auto;
        height: auto;
    }
    .sugarGoalsHeader{
        width: auto;
        height: auto;
    }
    .sugarGoalsSpan{
        width: 134px;
        font-size: 10px;
        line-height: 13px;
    }
    .goals1v1{
        width: 188px !important;
        top: 165px;
        right: 87px;
    }
    .goals2v1{
        bottom: 25px;
        left: 100px;
        width: 170px !important;
    }
    .goals3v1{
        width: 190px !important;
        left: 80px;
        bottom: 40px;
    }
    .goals4v1{
        bottom: 230px;
        right: 86px;
        width: 170px !important;
    }
    .goalsShadow{
        width: 250px;

    }
    .goalsLine{
        width: 100% !important;
    }
    .sugarGoalMobile{
        top: 15%;
    }
}