.shoesContainer{
    padding: 10px 0px 0px 0px;
    display: flex;
    justify-content: space-evenly;
    background: #EBEBEB;
    margin-top: 60px;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.shoesImageDiv{
    z-index: 1;
    width: 50%;
    height: 100%;
}

.shoesImage{
    width: 100%;
}

/* Reeponsive Tablet Settings */

.tablet .shoesContainer{
    flex-direction: column;
}

.tablet .shoesImageDiv{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
}

.shoesImage{
    width: 90%;
}   

.tablet .shoesImage{
    width: 45%;
}

/* Reeponsive Mobile Settings */

.mobile .shoesContainer{
    flex-direction: column;
    padding-top: 50px;
}

.mobile .shoesImageDiv{
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
}

.mobile .shoesImage{
    width: 75%;
    padding-top: 30px;
}