.ShowAtHomeContainer{
    padding: 50px 0px;
    display: flex;
    justify-content: space-evenly;
    background: #EBEBEB;
    margin-top: 60px;
    position: relative;
    align-items: center;
    overflow: hidden;
}
.showAtHomeBubbles{
    position: absolute;
    height: 1000px;
}
.showAtHomeSpans{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 60%;
    z-index: 2;
    gap: 50px;
}
.showAtHomeHeader{
    width: 80%;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    color: #000000;
}
.showAtHomeSpan{
    width: 80%;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 34px;
    display: flex;
    align-items: center;
    color: #000000;
}
.showAtHomeImage{
    width: 100%;
}
.mobile .showAtHomeImage{
    padding-top: 30px;
}
.showAtHomeButtons{
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 80%;
}
.showAtHomeButtons.joint{
    width: 90%;
}
.showAtHomeButtons.metaverse{
    width: 75%;
}
.showAtHomeSpanElements{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 80%;
    width: 82%;
    gap: 30px;
}
.showAtHomeSpanElements.metaverse{
    width: 75%;
}
.showAtHomeSpanElements.joint{
    width: 90%;
}
.showAtHomeButton1 a{
    width: 100%;
    height: 18px;
    font-style: normal;
    font-weight: 700;
    font-size: 15.9998px;
    line-height: 18px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    color: #FFFFFF;
  }
.showAtHomeButton1{
    border: none;
    background: #ED401A;
    border-radius: 30px;
    cursor: pointer;
    position: relative;
    padding: 10px 15px;
    color: #FFFFFF;
    white-space: nowrap;
}
.showAtHomeButton1:hover{
    background: #ED401A;
    color: white;
}
.showAtHomeButton1:hover a{
  color: white;
}
.showAtHomeButton2:hover,
.showAtHomeButton2 a:hover{
    background: #ED401A;
    color: white;
}
.showAtHomeButton2 a{
  height: 26px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 200px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    color: #ED401A;
}
.showAtHomeButton2:hover a {
  color: white;
}
.showAtHomeButton2{
    width: 129px;
    height: 44px;
    border: 2px solid #ED401A;
    border-radius: 100px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.showAtHomeImageDiv{
    z-index: 1;
}

.productInputsCloseButton{
    position: absolute;
    z-index: 999;
    top: 14%;
    right: 27%;
    cursor: pointer;
}

/* Reeponsive Tablet Settings */

.tablet .showAtHomeHeader{
    font-size: 27px;
    line-height: 42px;
}
.tablet .showAtHomeSpan{
    font-size: 20px;
}
.tablet .showAtHomeImageDiv{
    right: auto;
    bottom: 0px;
    display: flex;
    justify-content: center;
}
.tablet .showAtHomeButtons{
    bottom: 0px;;
}

/* Reeponsive Mobile Settings */

.mobile .ShowAtHomeContainer{
    flex-direction: column;
}

.mobile .showAtHomeHeader{
    width: auto;
    font-size: 27px;
    line-height: 35px;
    position: relative;
}
.mobile .showAtHomeSpan{
    width: 100%;
    line-height: 25px;
    font-size: 17px;
    position: relative;
}
.mobile .showAtHomeButton1{
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.mobile .showAtHomeButton1 a{
    width: 90px;
    height: 26px;
    font-size: 13px;
}
.mobile .showAtHomeButton2{
    width: 110px;
    height: 35px;
}
.mobile .showAtHomeButton2 a{
    width: 100px;
    height: 26px;
    font-size: 11px;
    line-height: 12px;
}
.mobile .showAtHomeImageDiv{
    display: flex;
    justify-content: center;
}
.mobile .showAtHomeButtons{
    gap: 20px;
}
.mobile .showAtHomeSpanElements{
    width: 100%;
}
.mobile .showAtHomeSpans{
    width: 83%;
    align-items: normal;
    gap: 30px;
}
.mobile .productInputsCloseButton{
    top: 11%;
    right: 10%;
}