.footerContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 10vh;
    gap: 50px;
    position: relative;
}
.footerElements{
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    bottom: 10px;
}
.footerSpan{
    width: 75%;
    height: 35px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    color: #797979;
    display: flex;
}
.footerMediaButtons{
    display: flex;
    gap: 20px;
}
.footerMediaButtons img{
    cursor: pointer;
    opacity: 0.5;
}

/* Reeponsive Tablet Settings */

.tablet .footerElements{
    flex-direction: column;
    align-self: center;
}
.tablet .footerSpan{
    width: 100%;
    font-size: 11px;
    justify-content: center;
}
.tablet .footerMediaButtons{
    justify-content: center;
}

/* Reeponsive Mobile Settings */

.mobile .footerElements{
    flex-direction: column;
    align-self: center;
}
.mobile .footerSpan{
    width: 100%;
    font-size: 11px;
    justify-content: center;
}
.mobile .footerMediaButtons{
    justify-content: center;
}