.rotate_open_slide0 {
    animation: rotate_open 0.5s linear;
    animation-fill-mode: forwards;
}

:root {
  --min-scale: 0.6;
  --max-scale: 1;
}

@keyframes rotate_open {
    from {
        transform: translate(calc(-50%), calc(-50%))rotate(-180deg);
    }

    to {
        transform: translate(calc(-50%), calc(-50%))rotate(0deg);
    }
}


@keyframes rotate_close {
    from {
        transform: translate(calc(-50%), calc(-50%))rotate(0deg);

    }

    to {
        transform: translate(calc(-50%), calc(-50%))rotate(180deg);
    }
}

.wheelAnimationItemsContainer {
    position: relative;
    left: -50px;
    bottom: -50px;
    scale: 0.8;
}

@media (max-width: 1100px) {
  .wheelAnimationItemsContainer {
    transform: scale(var(--min-scale));
  }
}

.mobile .wheelAnimationItemsContainer{
    margin-left: 0;
    scale: 0.9;
    right: 50px;
    bottom: 70px;
}

.wheelAnimationItems {
    width: 100%;
    height: 100%;
    position: absolute;
}

.wheelAnimationItem {
    width: 200%;
    height: 200%;
    position: absolute;
    transform: translate(calc(-50%), calc(-50%))rotate(0deg);
}

.wheelAnimationItemInner {
    width: 50%;
    height: 50%;
    position: absolute;
    bottom: 40px;
    right: 0px
}

.tablet .wheelAnimationItemsContainer{
    right: 50px;
    scale: 1.2;
}
