.contactCvContainer{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
    border-bottom: 1px solid lightgray;
}
.contactCvInputs{
    width: 770px;
    background: rgba(255, 255, 255, 0.4);
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(15px);
    border-radius: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 20px 0px;
}
.jobTitleInputs{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 85%;
}
.jobTitleSelect{
    height: 40px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D2D6DA;
    border-radius: 8px;
}
input[type=file]::file-selector-button {
    margin-right: 20px;
    margin-top: 5px;
    margin-left: 5px;
    border: none;
    border: 2px solid #ED401A;
    background: #FFFFFF;
    padding: 5px 5px;
    border-radius: 10px;
    color: #ED401A;
    cursor: pointer;
  }
.cvAlert{
    height: 22px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 7px;
    display: flex;
    align-items: center;
    color: #737373;
}
.addCvInput{
    width: 150px;
    height: 19px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 66px;
    display: flex;
    align-items: center;
    color: #252F40;
}
.contactCvHeader{
    height: 41px;
    font-style: normal;
    position: relative;
    font-weight: 700;
    font-size: 30px;
    line-height: 0px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.02666em;
    background: linear-gradient(135deg, #FFA895 15.1%, #FF2E00 67.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.submitButton{
    height: 40px;
    width: 626px;
    background: linear-gradient(135deg, #FFA895 15.1%, #FF2E00 67.71%);
    box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
}
.contactOptions{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 85%;
}
.contactCvSpan{
    width: 90%;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #67748E;
}
.contactOption{
    display: flex;
    gap: 10px;
    align-items: flex-start;
}
.contactOption img{
    width: 13px;
    height: 13px;
    border-radius: 10px;
    cursor: pointer;
    background: #CCCCCC;
}
.contactOption label{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #717171;
}

input[type="radio"]:checked{
    background-color: #ED401A;
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #0091ea;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spinner-animation 1s linear infinite;
}

#informationLoadingPopup{
    display: none;
    flex-direction: column;
    position: absolute;
    height: 360px;
    gap: 80px;
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    border-radius: 26px;
    z-index: 999999;
    padding: 0px 20px;
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


  .loading-animation {
    display: flex;
  }
  
  .loading-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;
    background-color: #ccc;
    animation: loading-animation 3s infinite;
  }
  
  @keyframes loading-animation {
    0% {
      background-color: #ccc;
    }
    33% {
      background-color: #ccc;
    }
    66% {
      background-color: #ccc;
    }
    100% {
      background-color: red;
    }
  }




.nameEmailInputsCv{
    display: flex;
    justify-content: space-between;
    width: 85%;
}
.nameInputsCv, .emailInputsCv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 45%;
}
.nameEmailInputCv{
    height: 4.5vh;
    min-width: 200px;
    max-width: 300px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #D2D6DA;
}
.mobile .nameEmailInputCv.phone{
    width: 300px;
}
.cvInput{
    height: 4.5vh;
    min-width: 200px;
    max-width: 300px;
    border-radius: 8px;
    background: #FFFFFF;
    border: 1px solid #D2D6DA;
}
.descriptionInputsCv{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 85%;
}


/* Reeponsive Mobile Settings */

.mobile .contactCvInputs{
    width: 370px;
    gap: 30px;
}

.mobile .jobTitleSelect{
    width: 300px;
    height: 4.5vh;
}

.mobile .contactCvRecaptcha{
    right: auto !important;
}

.mobile .submitButton{
    width: 200px;
}

.mobile .contactOptions{
    gap: 20px;
    left: 20px;
    bottom: 190px;
}

.mobile .contactCvHeader{
    left: auto;
    width: auto;
}

.mobile .contactOption label{
    font-size: 14px;
    line-height: 19px;
}

.mobile .nameEmailInputsCv{
    flex-direction: column;
}

.mobile .nameInputsCv, .mobile .emailInputsCv{
    width: auto;
}

.mobile .cvInput{
    height: 5.5vh;
}