.contactAddressInformation{
    display: flex;
    width: 843px;
    height: 410px;
    background: #FFFFFF;
    opacity: 0.8;
    box-shadow: 0px 4px 40px #000000;
    border-radius: 29px;
}

.contactAddressContainer{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 200px 0px;
}
.addressContainer{
    display: flex;
    gap: 10px;
    position: relative;
    left: 40px;
}
.addressSpan{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.contactAddress{
    gap: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
}
.contactLogo2{
    position: relative;
    width: 60%;
    cursor: pointer;
    left: 50px;
}
.location{
    position: relative;
    left: 100px;
    width: 470px;
    height: 298px;
    border-radius: 20px;
    border: 2px solid #A5A5A5;
    top: 50px;
}
.locationMobile{
    display: none;
}
.communicationAddress{
    display: flex;
    gap: 20px;
    position: relative;
}
.addressSpanMobile{
    height: 19px;
    width: 308px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: -0.3px;
    text-transform: capitalize;
    color: #474747;
}
.phoneNumberSpanMobile{
    height: 19px;
    width: 115px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    text-transform: capitalize;
    color: #474747;
    display: flex;
    flex-direction: column;
}
.mailAdressSpanMobile{
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
    color: #474747;
    display: flex;
    flex-direction: column;
}
.contactAddressInformationMobile{
    display: none;
}
.contactLogoMobile{
    position: relative;
    width: 90px;
    bottom: 0px;
}


@media screen and (max-width: 800px){
    .contactAddressContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .contactAddressInformation{
        display: none;
    }
    .location{
        display: none;
    }
    .locationMobile{
        width: 100%;
        height: 298px;
        display: block;
    }
    .contactAddressInformationMobile{
        display: flex;
        flex-direction: column;
        width: 370px;
        height: 189px;
        background: #FFFFFF;
        opacity: 0.8;
        box-shadow: 0px 4px 40px #000000;
        border-radius: 29px;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
    .addressContainer{
        left: auto;
    }
    .phoneNumberContainer{
        left: auto;
    }
    .mailAdressContainer{
        left: auto;
    }
}

@media screen and (max-width: 499px) {
    .contactAddressContainer{
        height: 81vh;
        justify-content: flex-start;
        padding-top: 30px;
        gap: 60px;
        padding-bottom: 50px;
    }
}