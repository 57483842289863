.headerContainer {
	display: flex;
	justify-content: space-between;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #F2F2F2;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	z-index: 9999;
	height: 70px;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 0%;
	height: 0%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: -1;
}

.sugarLogo {
	position: relative;
	width: 160.08px;
	height: 58.46px;
	top: 15px;
	left: 30px;
	cursor: pointer;
	padding-left: 5%;
}

.routers {
	display: flex;
	justify-content: center;
	gap: 40px;
	margin-top: 25px;
	position: relative;
}

.routers a {
	color: #170F49;
	cursor: pointer;
	text-decoration: none;
}

.itemsPopup a {
	color: #170F49;
	cursor: pointer;
	text-decoration: none;
}

.popupRouters {
	display: flex;
	flex-direction: column;
	gap: 5px;
	position: relative;
	left: 10px;
}

.popupMediaCenterButton {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	gap: 4px;
	width: 154px;
	height: 48px;
	background: #ED401A;
	border-radius: 76px;
	border: none;
	position: relative;
	top: 20px;
	cursor: pointer;
}

.mobile .popupMediaCenterButton {
	top: 10px;
}

.popupMediaCenterButton a {
	width: 105px;
	height: 18px;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 18px;
	text-align: justify;
	color: #FFFFFF;
	text-decoration: none;
}

.popupRouterMobile {
	height: 40px;
	width: 264px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: transparent;
	position: relative;
	font-size: 14px;
	border-radius: 8px;
	padding-left: 10px;
}

.firstMobilePopup a {
	width: 250px;
	height: 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	letter-spacing: -0.0032em;
	font-feature-settings: 'tnum' on, 'lnum' on;
	color: #000000;
	cursor: pointer;
}

.firstMobilePopup {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background: linear-gradient(180deg, #EAEAEA 0%, rgba(255, 255, 255, 0.00) 33%);
}

.firstChildPopup a {
	cursor: pointer;
}

.firstChildPopup a:hover,
.firstMobilePopup a:hover {
	color: #ED401A;
}

.popupItem {
	width: 100% !important;
}

.popupItem:hover {
	color: #ED401A;
}

.firstChildPopup {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background: linear-gradient(180deg, #EAEAEA 0%, rgba(255, 255, 255, 0.00) 55%);
	margin-top: 5px;
}

.secondChildPopup {
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	background: linear-gradient(180deg, #EAEAEA 0%, rgba(255, 255, 255, 0.00) 55%);
	margin-top: 5px;
}

.popupRouter {
	height: 40px;
	width: 264px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #FFFFFF;
	border-radius: 8px;
	position: relative;
	padding-left: 10px;
}

.popupRouter a {
	width: 240px;
	height: 20px;
	font-style: normal;
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #353535;
	text-decoration: none;
}

.routers a:hover {
	color: red;
}

.popupRouter:visited {
	color: #353535;
}

.headerButtons {
	display: flex;
	gap: 20px;
	margin-top: 18px;
	position: relative;
	right: 40px;
	padding-right: 5%;
}

.mediaCenterButton {
	width: 125px;
	height: 34px;
	background: #ED401A;
	border-radius: 76px;
	border: none;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
}

.popupMediaCenterButton:hover,
.popupMediaCenterButton a:hover {
	background-color: #F3E7E6;
	color: #d23b1a;
}

.popupMediaCenterButton a {
	color: white;
}

.popupMediaCenterButton:hover a {
	color: red;
}

.mediaCenterButton:hover,
.mediaCenterButton a:hover {
	background-color: #d23b1a;
	color: white;
}

.mediaCenterButton a {
	color: red;
	text-decoration: none;
}

.mediaCenterButton:hover a {
	color: white;
}

.mediaCenterButton a {
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	text-align: justify;
	color: #FFFFFF;
}

.productServicesPopup {
	width: 208px;
	height: 140px;
	position: absolute;
	left: 70px;
	top: 100%;
	padding: 5px 10px;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: #FFFFFF;
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.3);
	z-index: 6,
}

.productServicesPopup a,
.productServicesPopup span {
	width: 162px;
	height: auto;
	min-height: 24px;
	position: relative;
	padding-left: 5px;
	border-radius: 8px;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 13px;
	text-decoration: none;
}

.popupOption:hover {
	background: #FFE9E7;
}

.productServicesPopup a:visited {
	color: #353535;
}

.popupOption {
	width: 100%;
	height: auto;
	min-height: 30px;
	position: relative;
	display: flex;
	justify-content: space-between;
	border-radius: 8px;
}

.popupVector {
	width: 10px;
	position: relative;
}

.popupLine {
	width: 200px;
	position: relative;
	left: 5px;
}

.popupLineSmall {
	width: 150px;
	height: 1.5px;
	object-fit: cover;
}

.secondPopup {
	width: 150px;
	height: auto;
	top: calc(100% - 50px);
	left: 100%;
	position: absolute;
	padding: 15px 8px;
	border-radius: 12px;
	z-index: 6;
	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-evenly;
	background: #ECECEC;
	box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18), 0px 4px 8px rgba(97, 97, 97, 0.18);
}

.secondPopup a {
	text-decoration: none;
	color: #545454;
}

.firstPopup a {
	text-decoration: none;
	color: #545454;
}

.firstPopup a:hover {
	color: #ED401A;
}

.secondPopup a:hover {
	color: #ED401A;
}

.secondPopup a:visited {
	color: #353535;
}

.secondPopupFirstOption:hover,
.secondPopupSecondOption:hover {
	background: #FFE9E7;
}

.secondPopupFirstOption {
	display: flex;
	border-radius: 8px;
	height: 22px;
	align-items: center;
}

.secondPopupSecondOption {
	display: flex;
	border-radius: 8px;
	height: 22px;
	align-items: center;
	padding-bottom: 5px;
}

.firstPopupFirstOption:hover,
.firstPopupSecondOption:hover,
.firstPopupThirdOption:hover {
	background: #FFE9E7;
}

.firstPopupFirstOption {
	display: flex;
	width: 150px;
	border-radius: 8px;
	height: 22px;
	align-items: center;
}

.firstPopupSecondOption {
	display: flex;
	width: 150px;
	border-radius: 8px;
	height: 22px;
	align-items: center;
}

.firstPopupThirdOption {
	display: flex;
	width: 150px;
	border-radius: 8px;
	height: 22px;
	align-items: center;
}

.firstPopup {
	width: 155px;
	position: absolute;
	top: 0px;
	left: 100%;
	padding: 15px;
	z-index: 6;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	background: #ececec;
	box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18), 0px 4px 8px rgba(97, 97, 97, 0.18);
}

.headerShowButton {
	display: flex;
	width: 50px;
	position: relative;
	left: 10px;
}

.mobileHeaderPopup {
	width: 280px;
	position: relative;
	top: 100%;
	padding: 30px 10px;
	border-bottom-right-radius: 12px;
	display: flex;
	gap: 10px;
	flex-direction: column;
	align-items: flex-start;
	overflow-y: scroll;
	overflow-x: hidden;
	background: #FFF;
	box-shadow: 0px 2px 4px rgba(97, 97, 97, 0.18), 0px 4px 8px rgba(97, 97, 97, 0.18);
}

.mobileHeaderPopup a {
	cursor: pointer;
	text-decoration: none;
}

.mobileHeaderPopup a:hover {
	color: #ED401A;
}

.mobilePopupLine {
	width: 90%;
}

.backToHome {
	display: flex;
	gap: 10px;
	flex-direction: row;
	position: relative;
	left: 5px;
	padding-left: 10px;
}

.backToHomeInput {
	height: 40px;
	width: 264px;
	background: #E0E0E0;
	opacity: 0.5;
	border-radius: 8px;
	border: none;
	caret-color: red;
}

.backToHomeInput::placeholder {
	position: relative;
	left: 10px;
}

.backToHomeSpan {
	width: 200px;
	height: 24px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 150%;
	display: flex;
	align-items: center;
	letter-spacing: 0.0168em;
	font-feature-settings: 'tnum' on, 'lnum' on;
	color: #353535;
}

.popupRightButton {
	width: 10px;
	cursor: pointer;
	position: relative;
	right: 10px;
}

.popupRouter:hover,
.popupRightButton:hover {
	color: red;
}

.backToHomeArrow {
	cursor: pointer;
}

.itemsPopup {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.hidden {
	display: none;
}

/* Reeponsive Tablet Settings */

.tablet .headerContainer {
	justify-content: normal;
	gap: 100px;
}

.tablet .headerShowButtons {
	display: flex !important;
}

.tablet .headerShowButton {
	display: flex !important;
}

.tablet .mediaCenterButton {
	display: none;
}

.tablet .productServicesPopup {
	display: flex;
	position: absolute;
	top: 90px;
	left: 0px;
	height: 120px;
	z-index: 6;
	box-shadow: none;
}

.tablet .headerButtons {
	right: 0px;
}

/* Reeponsive Mobile Settings */

.mobile .headerContainer {
	justify-content: normal;
	gap: 100px;
}

.mobile .headerShowButtons {
	display: flex !important;
}

.mobile .headerShowButton {
	display: flex !important;
}

.mobile .mediaCenterButton {
	display: none;
}

.mobile .productServicesPopup {
	display: flex;
	position: absolute;
	top: 90px;
	left: 0px;
	height: 120px;
	z-index: 6;
	box-shadow: none;
}

.mobile .headerButtons {
	right: 0px;
}