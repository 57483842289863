.tryCloothContainer{
    display: flex;
    padding: 50px 0px;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
}
.tryCloothElements{
    width: 45%;
    gap: 30px;
    display: flex;
    flex-direction: column;
}
.tryCloothHeader{
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    color: #000000;
}
.tryCloothSpan{
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-transform: capitalize;
    color: #000000;
}
.tryCloothElementImage{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Reeponsive Tablet Settings */

.tablet .tryCloothElements{
    position: absolute;
    top: 0px;
    width: 100%;
    align-items: center;
}

.tablet .tryCloothImages{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
}

.tablet .tryCloothImage{
    width: 30% !important;
}

.tablet .tryCloothHeader{
    height: 100px;
    width: 100%;
    text-align: justify;
}

.tablet .tryCloothSpan{
    width: 70%;
    height: 150px;
    text-align: justify;
}

.tablet .tryCloothBubble{
    display: none;
}

/* Reeponsive Mobile Settings */

.mobile .tryCloothContainer{
    flex-direction: column;
}

.mobile .tryCloothElements{
    width: 90%;
    align-items: center;
    gap: 20px;
}

.mobile .tryCloothImages{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
}

.mobile .tryCloothImage{
    width: 65% !important;
    padding-top: 30px;
}

.mobile .tryCloothHeader{
    width: 100%;
    text-align: center;
    font-size: 30px;
}

.mobile .tryCloothSpan{
    width: 100%;
    text-align: center;
}

.mobile .tryCloothBubble{
    display: none;
}

.mobile .tryCloothElementImage{
    align-items: center;
}

.mobile .tryCloothElementImage1{
    width: 80% !important;
}

.mobile .tryCloothElementImage2{
    width: 80% !important;
}