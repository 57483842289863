.cloothPaternContainer{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #E9E9E9;
    position: relative;
    justify-content: space-evenly;
    gap: 50px;
}
.cloothPaternSpans{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    gap: 50px;
}
.cloothPaternHeader{
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    text-align: justify;
    color: #000000;
}
.cloothPaternSpan{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
}
.cloothPaternElements{
    display: flex;
    gap: 80px;
}
.cloothPaternImage1{
    width: 350px;
}
.cloothPaternImage2{
    width: 200px;
}

/* Reeponsive Tablet Settings */

.tablet .cloothPaternSpans{
    width: 100%;
    align-items: center;
}

.tablet .cloothPaternHeader{
    width: 100%;
    font-size: 30px;
}

.tablet .cloothPaternSpan{
    width: 80%;
    font-size: 16px;
    line-height: 25px;
}

.tablet .cloothPaternVector{
    width: 30px;
    position: relative;
    transform: rotate(90deg);
}

.tablet .cloothPaternElements{
    flex-direction: column;
    align-items: center;
    gap: 35px;
}

.tablet .cloothPaternImage1{
    width: 60%;
}

.tablet .cloothPaternImage2{
    width: 50%;
}

/* Reeponsive Mobile Settings */

.mobile .cloothPaternVector{
    width: 30px;
    position: relative;
    transform: rotate(90deg);
}

.mobile .cloothPaternSpans{
    width: 90%;
    align-items: center;
    top: 0px;
}

.mobile .cloothPaternHeader{
    width: 100%;
    font-size: 30px;
    text-align: center;
}

.mobile .cloothPaternSpan{
    width: 80%;
    font-size: 16px;
    line-height: 25px;
}

.mobile .cloothPaternElements{
    flex-direction: column;
    align-items: center;
    gap: 35px;
}

.mobile .cloothPaternImage1{
    width: 60%;
}

.mobile .cloothPaternImage2{
    width: 50%;
}

