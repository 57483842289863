.experienceReportsContainer{
    padding: 50px 0px;
    display: flex;
    justify-content: center;
    background: #EFEFEF;
    position: relative;
}
.reportsSpanElement{
    display: flex;
    flex-direction: column;
    gap: 26px;
    width: 40%;
    height: 80%;
    right: 84px;
}
.reportsSpanImages{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.reportsFirstSpan{
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #ED401A;
}
.reportsSecondSpan{
    width: 100%;
    display: flex;
    font-style: normal;
    font-weight: 250;
    font-size: 20px;
    line-height: 26px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
}
.reportsHeader{
    width: 100%;
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 66px;
    color: #000000;
}
.reportsSpangImgShadow{
    opacity: 0.3;
    width: 76%;
    
}

.experienceReportsDiv{
    display: flex;
    width: 88%;
    height: 100%;
    position: relative;
}

/* Reeponsive Tablet Settings */

.tablet .reportsSpanImages{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.tablet .reportsHeader{
    font-size: 23px;
    height: 70px;
}
.tablet .reportsFirstSpan{
    font-size: 25px;
}
.tablet .reportsSecondSpan{
    width: 60%;
}
.tablet .reportsSpanElement{
    height: 50%;
    width: 100%;
    align-items: center;
    text-align: justify;
    gap: 0px;
}
.tablet .reportsSpansImg{
    width: 321px !important;
}
.tablet .reportsSpangImgShadow{
    display: flex;
    width: 321px;
}

/* Reeponsive Mobile Settings */

.mobile .reportsSpanImages{
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mobile .reportsSpansImg{
    width: 75%;
}
.mobile .reportsSpangImgShadow{
    position: initial;
}
.mobile .reportsHeader{
    font-size: 23px;
    height: 70px;
}
.mobile .reportsFirstSpan{
    font-size: 20px;
    height: 115px;
    line-height: 30px;
}
.mobile .reportsSecondSpan{
    width: 76%;
    font-size: 14px;
    line-height: 23px;
}
.mobile .reportsSpanElement{
    height: 50%;
    width: 100%;
    align-items: center;
    text-align: justify;
    gap: 0px;
}
.mobile .reportsSpangImgShadow{
    display: flex;
    width: 321px;
}

.mobile .experienceReportsDiv{
    flex-direction: column-reverse;
}