.contactInformContainer{
    padding: 200px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.mobile .contactInformContainer{
    background-position: center ;
}
.contactInform{
    width: 50%;
    height: 50%;
    padding: 50px 50px;
    background: #FFFFFF;
    opacity: 0.8;
    box-shadow: 0px 4px 40px #000000;
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;
}
.mobile .contactInform{
    width: 65%;
    padding: 30px 50px;
    gap: 20px;
}
.contactInformHeader{
    font-style: normal;
    font-weight: 700;
    font-size: 37px;
    line-height: 83px;
    letter-spacing: -1px;
    color: #000000;
}
.mobile .contactInformHeader{
    font-size: 25px;
    text-align: left;
    line-height: 30px;
}

.contactInformSpan{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #8A8A8A;
    mix-blend-mode: normal;
    width: 70%;
}
.mobile .contactInformSpan{
    width: 100%;
}

.contactInformOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}