.about-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: .2fr 1fr;
	padding: 3% 10%;
	position: relative;
	align-items: center;
	justify-content: center;
}

.about-header {
	grid-area: 1 / 1 / 2 / 3;
	font-style: normal;
	font-weight: 500;
	font-size: 38px;
	color: #000000;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.about-spans {
	grid-area: 2 / 1 / 3 / 2;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	gap: 25px;
	z-index: 1;
	align-items: flex-start;
}

.about-span {
	font-style: normal;
	font-weight: 300;
	font-size: 17px;
	line-height: 26px;
	width: 80%;
	text-align: left;
	color: #000000;
	position: relative;
}

.about-button {
	height: 44px;
	position: relative;
	padding: 3px 20px;
	border-radius: 30px;
	display: flex;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
	color: #FFFFFF;
	background: #ED401A;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
}

.about-image {
	grid-area: 2 / 2 / 3 / 3;
}

.about-background-shape {
	position: absolute;
	filter: blur(7.5px);
	right: 0px;
	bottom: 0px;
	z-index: -1;
}

/* Reeponsive Tablet Settings */

.tablet .about-header {
	width: 80%;
}

.tablet .about-spans {
	width: 100%;
	align-items: center;
	z-index: 1;
}

.tablet .about-image {
	display: flex;
	width: 100%;
	height: auto;
	justify-content: center;
}

.tablet .about-span {
	width: 80%;
	text-align: justify;
}

.tablet .about-button {
	font-size: 15px;
}

/* Reeponsive Mobile Settings */

.mobile .about-container {
	flex-direction: column;
}

.mobile .about-spans {
	width: 100%;
	left: 0px;
	top: 0px;
	align-items: center;
}

.mobile .about-span {
	width: 80%;
	text-align: justify;
	line-height: 20px;
	font-size: 14px;
}

.mobile .about-image {
	display: flex;
	width: 100%;
	height: auto;
	justify-content: center;
	padding-top: 50px;
}

.mobile .about-header {
	font-size: 30px;
	width: 80%;
}

.mobile .about-button {
	font-size: 14px;
}