.MetaverseContainer{
    padding: 50px 0px 0px 0px;
    display: flex;
    justify-content: space-evenly;
    background: #EBEBEB;
    margin-top: 60px;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.metaverseImage{
    width: 100%;
}
.metaverseImageDiv{
    z-index: 1;
    width: 41%;
    height: 100%;
    display: flex;
}

/* Reeponsive Mobile Settings */

.mobile .MetaverseContainer{
    flex-direction: column;
}

.mobile .metaverseImageDiv{
    width: auto;
    height: auto;
}

