.contactContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
}
.contactElements{
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 10px;
}
.leftContact{
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 50px;
}
.communication a{
    color: #797979;
    font-size: 19px;
    font-family: 'Nunito';
}
.about{
    display: grid;
    gap: 40px;
}
.contactAboutSpan{
    width: 200px;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: inherit;
    line-height: 148.4%;
    letter-spacing: -0.02em;
    color: #797979;
    position: relative;
}
.communication{
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    right: 40px;
}
.mailAdressContainer{
    display: flex;
    gap: 10px;
    position: relative;
    left: 40px;
}
.mailAdressSpan{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.phoneNumberContainer{
    display: flex;
    gap: 10px;
    position: relative;
    left: 40px;
}
.phoneNumberSpan{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}
.headers{
    height: 38px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 128.18%;
    text-transform: capitalize;
    color: #242331;
}
.contactHeaders{
    display: grid;
    gap: 20px;
}
.contactHeaders a{
    display: flex;
    height: 38px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 128.18%;
    text-transform: capitalize;
    color: #242331;
}
.contactHeaders img{
    position: relative;
    right: 10px;
}
.contactRouters{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 220%;
    text-transform: capitalize;
    gap: 10px;
    position: relative;
    top: 20px;
}
.contactRouters a{
    text-decoration: none;
    color: #797979;
    width: 100%;
}
.contactRouters a:hover{
    color: red;
    cursor: pointer;
}
.contactLogo{
    width: 160.08px;
    height: 58.46px;
    position: relative;
}
.middleContact{
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 32%;
}

@media screen and (max-width: 1200px) {
    .middleContact{
        width: 50%;
    } 
}    



.rightContact{
    display: flex;
    flex-direction: column;
    position: relative;
}
.rightContactInputs{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.rightContactInputs a{
    width: 320px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 148.4%;
    letter-spacing: -0.02em;
    color: #797979;
}
.nameInput{
    width: 307.5px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #D2D6DA;
    border-radius: 8px;
    padding: 8px;
    font-family: 'Nunito';
}
.emailInput{
    width: 307.5px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #D2D6DA;
    border-radius: 8px;
    padding: 8px;
    font-family: 'Nunito';
}
.messageInput{
    width: 307.5px;
    height: 70px;
    background: #FFFFFF;
    border: 1px solid #D2D6DA;
    border-radius: 8px;
    padding: 8px;
    font-family: 'Nunito';
}
.sendButton{
    width: 325px;
    height: 41.81px;
    background: #ED401A;
    box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border: none;
    cursor: pointer;
}
.sendButton a{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 7px;
    position: relative;
    text-align: justify;
    letter-spacing: -0.04em;
    color: #FFFFFF;
}
.sendButton:hover{
  background-color: red;
}
.inputPopupButton{
    position: relative;
}
.inputPopupButton img{
    position: absolute;
    right: 0px;
    top: 10px;
    cursor: pointer;
}
.inputDonePopup{
    display: none;
    flex-direction: column;
    position: absolute;
    height: 47vh;
    gap: 80px;
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(15px);
    border-radius: 26px;
    z-index: 999999;
    padding: 0px 30px;
}
.inputPopupHeader{
    height: 20.3px;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 0px;
    display: flex;
    align-items: center;
    text-align: justify;
    letter-spacing: -0.02666em;
    color: #ED401A;
}
.inputPopupSpan{
    width: 300px;
    height: 25.74px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    text-align: justify;
    color: #727272;
}
.inputPopupImg{
    width: 80px;
}
.inputPopupSpans{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}
.inputPopupLine{
    width: 300px;
    height: 1px;
}
.inputPopupSecondSpan{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 7px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #727272;
}

/* Reeponsive Tablet Settings */

.tablet .rightContact{
    display: none;
}
.tablet .utilityPages{
    height: 100%;
}
.tablet .contactElements{
    justify-content: space-around;
}

/* Reeponsive Mobile Settings */

.mobile .contactLogo{
    width: 91px !important;
    bottom: 20px;
}
.mobile .contactElements{
    position: relative;
}
.mobile .rightContact{
    display: none;
}
.mobile .utilityLine{
    display: none;
}
.mobile .contactHeaders a{
    font-size: 13px;
    /* height: 20px; */
}
.mobile .leftContact{
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.mobile .middleContact{
    width: 50%;
    height: 70%;
}
.mobile .contactRouters{
    font-size: 11px;
    gap: 0px;
}
.mobile .contactAboutSpan{
    font-size: 11px;
    width: 140px;
    bottom: 30px;
}
.mobile .mailAdressLogo img{
    width: 17px;
}
.mobile .phoneNumberLogo img{
    width: 16px;
}
.mobile .mailAdressSpan a{
    width: 99px;
    font-size: 11px;
}
.mobile .phoneNumberSpan a{
    width: 104px;
    font-size: 11px;
}
.mobile .pagesLine{
   display: none;
}
.mobile .pages{
    width: 50%;
}
.mobile .utilityPages{
    width: 50%;
}
.mobile .about{
    gap: 0px;
    display: flex;
    flex-direction: column;
}
.mobile .communication{
    /* bottom: 60px; */
    right: auto;
}
.mobile .inputDonePopup{
    width: 75%;
}