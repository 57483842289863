.jointContainer{
    padding: 50px 5%;
    display: flex;
    justify-content: center;
    background: #EBEBEB;
    margin-top: 60px;
    position: relative;
    align-items: center;
}
.jointImage{
    width: 100%;
    padding: 120px 0px;
}
.jointImageDiv{
    width: 70%;
    z-index: 1;
}

#jointMobileImage{
    display: none;
}

/* Reeponsive Tablet Settings */

.tablet .jointImageDiv{
    right: auto;
    bottom: 50px;
    justify-content: center;
}

/* Reeponsive Mobile Settings */

.mobile .jointContainer{
    flex-direction: column;
}
.mobile .jointImageDiv{
    width: 80%;
}

.mobile #jointMobileImage{
    display: block;
}

.mobile #jointDeskopImage{
    display: none;
}

.jointImage{
    padding: 0px;
}
