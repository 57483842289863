.customerCommentsContainer{
    width: 100%;
    padding: 50px 0px;
    position: relative;
    display: flex;
    align-items: center;
    background: radial-gradient(pink,#ED401A);
    overflow: hidden;
}
.commentsLeftButton{
    position: absolute;
    left: 120px;
    cursor: pointer;
    z-index: 1;
}
.commentsRightButton{
    position: absolute;
    right: 120px;
    cursor: pointer;
    z-index: 1;
}
.commentSpans{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
    overflow-y: scroll;
}
.commentContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 20px;
    width: 100%;
    transition: transform 0.5s ease;
}

.firstCommentSpan{
    width: 90%;
    
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 110%;
    text-align: center;
    color: #FFFFFF;
}
.secondCommentSpan{
    width: 100%;
    
    font-style: normal;
    font-weight: 800;
    font-size: 100px;
    line-height: 60%;
    text-align: center;
    color: #FFFFFF;
}
.thirdCommentSpan{
    width: 65%;
    
    font-style: italic;
    font-weight: 600;
    font-size: 22px;
    line-height: 157.4%;
    color: #FFFFFF;
    text-align: center;
    position: relative;
    bottom: 20px;
}
.commentCustomer{
    display: flex;
    flex-direction: column;
    text-align: justify;
    width: 100%;
    align-items: center;
}
.customerPhoto{
    width: 10%;
    border-radius: 100px;
    max-width: 200px;
    position: relative;
}
.commentCustomer h1{
    color: #FFFFFF;
    
    font-size: 23px;
}
.commentCustomer a{
    color: #FFFFFF;
    position: relative;
    bottom: 10px;
    text-align: center;
}
.commentPageButtons{
    position: relative;
    bottom: 190px;
    left: 665px;
}
.secondComment{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1434px;
    height: 707px;
    gap: 100px;
    background: #FFFFFF;
}
.thirdComment{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 1434px;
    height: 707px;
    gap: 100px;
    background: #F1DCD8;
}
.comments{
    display: flex;
    gap: 100px;
}
.commentHeader a{
    width: 550px;
    height: 73px;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 52px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
}
.customerComment{
    display: flex;
    flex-direction: column;
    width: 314px;
    height: 399px;
    background: #E9E9E9;
    border-radius: 16px;
    padding-left: 30px;
}
.commentSymbol{
    position: relative;
    bottom: 20px;
}
.commentSpan{
    width: 256px;
    height: 213px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
}
.commentOwner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.commentOwnerFirstSpan{
    width: 84px;
    height: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 157.4%;
    color: #000000;
}
.commentOwnerSecondSpan{
    width: 60px;
    height: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 157.4%;
    color: #000000;
}
.customerCommentsPageButtons{
    display: flex;
    gap: 10px;
    position: absolute;
    left: 695px;
    top: 680px;
}
.customerCommentsPageButtons img{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.commentStar{
    width: 100px;
    z-index: 1;
}
.redBackgroundCircle1{
    position: absolute;
    left: 0px;
    top: 0px;
}
.redBackgroundCircle2{
    position: absolute;
    right: 0px;
    bottom: 0px;
}
.redBackgroundCircle3{
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.companyLogo{
    width: 10%;
    margin-top: 20px;
}

.mobile .companyLogo{
    width: 30%;
}


.tablet .companyLogo{
    width: 20%;
}

/* Reeponsive Tablet Settings */

.tablet .commentsLeftButton{
    left: 10px;
}

.tablet .commentsRightButton{
    right: 10px;
}

.tablet .firstCommentSpan{
    font-size: 30px;
}

.tablet .thirdCommentSpan{
    font-size: 18px;
}

/* Reeponsive Mobile Settings */

.mobile .firstCommentSpan{
    font-size: 20px;
}
.mobile .thirdCommentSpan{
    font-size: 15px;
}
.mobile .commentsRightButton{
    right: 10px;
    width: 50px;
}
.mobile .commentsLeftButton{
    left: 10px;
    width: 50px;
}

.mobile .customerPhoto{
    width: 20%;
}

.mobile .secondCommentSpan{
    line-height: 60%;
}