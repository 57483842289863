.sectoralSolutionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    border-bottom: 1px solid lightgrey;
    margin: auto;
}
.sectoralSolutionHeader{
    display: flex;
    margin-top: 40px;
    gap: 20px;
    width: 100%;
    justify-content: space-between;
}
.mobile .sectoralSolutionHeader{
    flex-direction: column;
}
.sectoralSolutionSpan{
    font-style: normal;
    font-weight: 700;
    font-size: 39px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}
.sectoralSolutionLine{
    width: 761px;
    height: 4px;
    background: linear-gradient(90deg, #ED401A 1.58%, #9970C8 95.97%);
    transform: rotate(-180deg);
}
.solution{
    display: flex;
    gap: 50px;
    margin-bottom: 10%;
}
.solutionSpans{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-top: 15px;
    font-weight: 500;
}
.solutionHeader{
    width: 90%;
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
    line-height: 34px;
    color: #000000;
    display: flex;
    flex-direction: column;
}
.solutionSpan {
    width: 450px;
}
.solutionButton{
    width: 154px;
    height: 37px;
    box-sizing: border-box;
    border: 1px solid #ED401A;
    border-radius: 100px;
    background: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
}
.solutionButton a{
    width: 130px;
    height: 26px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 26px;
    display: flex;
    align-items: center;
    text-align: justify;
    justify-content: center;
    color: #ED401A;
}
.solutionPageButtons{
    display: flex;
    gap: 10px;
}
.solutionPageButtons img{
    width: 13px;
    height: 13px;
    border-radius: 10px;
    cursor: pointer;
}

.solution1Button{
    background: #ED401A;
}
.solution2Button{
    background: #CCCCCC;
}
.solution3Button{
    background: #CCCCCC;
}
.solution4Button{
    background: #CCCCCC;
}

#rightSpans {
    animation: slideRight 1s forwards;
}
.solutionShadow{
    animation: slideRight 1s forwards;
}
#leftSpans{
    animation: slideRight 1s forwards;
}
.rightImage{
    animation: slideRight 1s forwards;
}
.leftImage{
    animation: slideRight 1s forwards;
}

.solutionsDiv{
    display: flex;
    flex-direction: column;
    width: 65%;
    align-items: center;
}

.mobile .solutionsDiv{
    width: 90%;
}

@keyframes slideRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}
.sectoralSolutionHeadLine{
    width: 28%;
}
/* Reeponsive Tablet Settings */

.tablet .sectoralSolutionSpan{
    font-size: 29px;
}
.tablet .sectoralSolutionHeadLine{
    width: 20%;
}
.tablet .sectoralSolutionLine{
    position: relative;
    left: 170px;
    width: 350px;
}
.tablet .solutionHeader{
    width: 326px;
    font-size: 24px;
}
.tablet .solutionSpan{
    width: 354px;
    font-size: 13px;
}
.tablet .solution{
    flex-direction: column;
}

.tablet .solutionSpans{
    left: 30px;
    gap: 40px;
}
.tablet .rightImage{
    width: 100%;
}
.tablet .solutionShadow{
    display: flex !important;
    position: relative;
    left: 5px;
    width: 474px;
    bottom: 12px;
    opacity: 0.5;
}

/* Reeponsive Mobile Settings */

.mobile .sectoralSolutionSpan{
    font-size: 29px;
}
.mobile .sectoralSolutionLine{
    position: relative;
    width: 100%;
}
.mobile .solutionHeader{
    width: 80%;
    font-size: 24px;
}
.mobile .solutionSpan{
    width: 100%;
    font-size: 13px;
}
.mobile .solution{
    flex-direction: column;
    align-items: center;
    width: 90%;
}
.mobile .solution.width100{
    width: 100%;
}
.mobile .solutionSpans{
    position: relative;
    
    gap: 20px;
}
.mobile .rightImage{
    width: 100%;
}
.mobile .leftImage{
    width: 100%;
}
.mobile .solutionShadow{
    display: flex !important;
    width: 97%;
    position: relative;
    left: 5px;
    bottom: 10px;
    opacity: 0.5;
}
.mobile .soluttinImage{
    width: 100%;
}
.mobile #solution2, .mobile #solution4{
    flex-direction: column-reverse;
}
.mobile .sectoralSolutionHeadLine{
    width: 90%;
    align-self: center;
    height: 3px;
    object-fit: cover;
}