.sugarBrandsContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px 0px;
    background: #EFEFEF;
    gap: 50px;
    padding-bottom: 150px;
}
.mobile .sugarBrandsContainer{
    gap: 0;
    overflow: hidden;
}
.sugarBrandsHeader{
    display: flex;
    width: 100%;
    height: 73px;
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;
    text-transform: capitalize;
    color: #000000;
}
.sugarBrands{
    display: flex;
    gap: 77px;
}
.sugarBrand{
    display: flex;
    background: #FFFFFF;
    border-radius: 16px;
    justify-content: space-evenly;
    flex-direction: column;
    position: relative;
    align-items: center;
    gap: 20px;
    padding: 0px 0px 20px 0px;
}
.sugarBrandHomeImg{
    position: relative;
    width: 288px;
    border-radius: 16px 16px 0px 0px;
}
.sugarBrandShadow{
    position: absolute;
    width: 275px;
    bottom: -19px;
    /* left: 13px; */
    opacity: 0.3;
}
.sugarBrandSpan{
    width: 270px;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    color: #000000;
    position: relative;
    margin: auto;
    height: 100%;
}
.sugarBrandLogo{
    width: 80%;
    height: 43px;
}
.brandsPageButton{
    display: flex;
    gap: 10px;
}
.brandsPageButton img{
    width: 13px;
    height: 13px;
    border-radius: 10px;
    cursor: pointer;
}

.sugarBrandButton{
    border-radius: 100px;
    border: 2px solid #ED401A;
    padding: 10px 0px;
    width: 85%;
    color: #ED401A;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    cursor: pointer;
}

/* Reeponsive Mobile Settings */

.mobile .sugarBrandsHeader{
    width: 90%;
    font-size: 22px;
    margin: auto;
    line-height: 30px;
    text-align: center;
}
.mobile .sugarBrandShadow1,
.mobile .sugarBrandShadow2,
.mobile .sugarBrandShadow3{
    display: none;
}
.mobile .brandsPageButton{
    display: flex !important;
}
.mobile .sugarBrandShadow1{
    top: 2420px;
    left: 120px;
}
.mobile .sugarBrandShadow2{
    top: 2420px;
    left: 120px;
}
.mobile .sugarBrandShadow3{
    top: 2420px;
    left: 120px;
}

.mobile .sugarBrands{
    padding: 40px 0px;
    overflow-x: scroll;
    gap: 15px;
    width: 100%;
    padding-top: 10px;
    padding-left: 40px;
}


/* Reeponsive Tablet Settings */

.tablet .sugarBrandsHeader{
    width: 100%;
    font-size: 27px;
}
.tablet #brand2,
.tablet #brand3{
    display: none;
}
.tablet .sugarBrandShadow1,
.tablet .sugarBrandShadow2,
.tablet .sugarBrandShadow3{
    display: none;
}
.tablet .brandsPageButton{
    display: flex !important;
    margin-top: 20px;
}
.tablet .sugarBrandShadow1{
    top: 2420px;
    left: 120px;
}
.tablet .sugarBrandShadow2{
    top: 2420px;
    left: 120px;
}
.tablet .sugarBrandShadow3{
    top: 2420px;
    left: 120px;
}