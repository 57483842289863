/* ENDLESS ROTATE */

/* .rotate {
  animation: rotate 90s linear infinite;
  animation-direction: alternate;
} */

.bright {
	animation: bright 5s linear infinite;
	animation-direction: alternate;
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

@keyframes bright {
	to {
		filter: brightness(1.5);
	}
}

.mainPageSliderContainer {
	width: 100%;
	height: calc(100vh - 70px);
	background: #ebebeb;
	position: relative;
	max-height: 826px;
	overflow: hidden;
	margin-top: 70px;
}

.mainPageSliderItem {
	height: 30px;
	width: 30px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mainPageTopLeftFigure {
	position: absolute;
	background-image: url(/public/MainPageSliderBackground/top_left.png);
	background-size: cover;
	width: 854px;
	height: 843px;
	top: calc(-200px);
	left: calc(-270px);
}

.mainPageTopRightFigure {
	position: absolute;
	background-image: url(/public/MainPageSliderBackground/top_right.png);
	width: 695px;
	height: 770px;
	top: -550px;
	right: 0%;
	background-size: cover;
}

.mainPageMiddleFigure {
	position: absolute;
	background-image: url(/public/MainPageSliderBackground/middle.png);
	background-size: cover;
	width: 474px;
	height: 367px;
	left: calc(50%);
	top: calc(50%);
	transform: translate(-200px, 65px);
}

.mainPageSmallFigure {
	position: absolute;
	background-image: url(/public/MainPageSliderBackground/small.png);
	width: 509px;
	height: 529px;
	left: -263px;
	bottom: -100px;
	background-size: cover;
}

.mainPageSmallFigure0 {
	position: absolute;
	background-image: url(/public/MainPageSliderBackground/small.png);
	width: 125px;
	height: 125px;
	right: 63px;
	top: 50px;
	background-size: cover;
}

.mobile .mainPageSmallFigure0 {
	right: 24px;
	top: 298px;
}

.smallSliderMenu {
	width: 30%;
	height: 54px;

	position: absolute;
	left: 160px;
	bottom: 33px;
	padding: 1px 15px;
	border-radius: 20px;
	border: 1px solid #ffffff;
	z-index: 9;

	display: flex;
	gap: 10px;
	align-items: center;
	justify-content: space-around;

	background: rgba(255, 255, 255, 0.2);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 0px 8px rgba(255, 255, 255, 0.9);
	filter: blur(0.2px);
}

.slideDetail {
	position: absolute;
	width: 35rem;
	right: 0;
	bottom: 150px;
	padding: 30px 0 30px 30px;
	background: rgba(255, 255, 255, 0.3);
	border: 2px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(15px);
	/* Note: backdrop-filter has minimal browser support */

	filter: 8px;
	border-top-left-radius: 42px;
	border-bottom-left-radius: 42px;
	animation: 1s slideRight 0s forwards;
}

.wheelAnimationFoot {
	width: 260px;
	height: 370px;
	position: absolute;
	background-image: url(/public/MainPageSliderItems/foot.png);
	bottom: -50px;
	right: 100px;
	transform: rotate(-15deg);
	visibility: hidden;
}

.wheelAnimationCamera {
	width: 190px;
	height: 387px;
	position: absolute;
	background-image: url(/public/MainPageSliderItems/phone.png);
	bottom: -109px;
	right: 140px;
	transform: rotate(68deg) translate(85px, -35px);
	visibility: hidden;
}

.wheelAnimationShoe {
	width: 250px;
	height: 145px;
	position: absolute;
	background-image: url(/public/MainPageSliderItems/shoe.png);
	bottom: -109px;
	right: 140px;
	transform: rotate(344deg) translate(125px, -20px);
	visibility: hidden;
}

.animationSofa {
	position: absolute;
	width: 373.4px;
	height: 186.56px;
	left: 121px;
	top: -398px;
	background-image: url(/public/assets/svg/fantasy/sofa.svg);
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 1;
	visibility: hidden;
}

.animationStartSofa {
	animation: 1s sofa0 1s ease-out, 1.4s sofa1 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopSofa {
	animation: 1s sofa2 ease-out;
	animation-fill-mode: forwards;
}

@keyframes sofa0 {
	to {
		visibility: visible;
		width: 373.4px;
		height: 186.56px;
		left: 124.2px;
		top: 278px;
	}
}

@keyframes sofa1 {
	to {
		width: 284.8px;
		height: 142.29px;
		left: 228px;
		top: 306px;
	}
}

@keyframes sofa2 {
	from {
		width: 284.8px;
		height: 142.29px;
		left: 228px;
		top: 306px;
		visibility: visible;
	}

	to {
		width: 284.8px;
		height: 142.29px;
		left: -347px;
		top: 780px;
		visibility: hidden;
	}
}

/* @media screen and (max-width: 420px) {
  @keyframes sofa0 {
    to {
      width: 273.4px;
      height: 156.56px;
      left: 124.2px;
      top: 278px;
    }
  }
  
  @keyframes sofa1 {
    to {
      width: 184.8px;
      height: 152.29px;
      left: 228px;
      top: 306px;
    }
  }
  
  @keyframes sofa2 {
    from {
      width: 184.8px;
      height: 122.29px;
      left: 228px;
      top: 306px;
    }
  
    to {
      width: 184.8px;
      height: 112.29px;
      left: -347px;
      top: 780px;
      visibility: hidden;
    }
  
}
} */

.animationIphone {
	position: absolute;
	width: 190.61px;
	height: 386.38px;
	left: -273.6px;
	top: 206.59px;
	background: url(/public/assets/svg/fantasy/iphone.svg);
	background-size: contain;
	transform: rotate(9.86deg);
	z-index: 2;
	visibility: hidden;
}

.animationStartIphone {
	animation: 1s ihpone0 1s ease-out, 1.4s ihpone01 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopIphone {
	animation: 1s ihpone02 ease-in;
	animation-fill-mode: forwards;
}

@keyframes ihpone0 {
	to {
		width: 190.61px;
		height: 386.38px;
		left: 229.07px;
		top: 161.45px;
		transform: rotate(105.22deg);
		visibility: visible;
	}
}

@keyframes ihpone01 {
	to {
		width: 215.48px;
		height: 436.79px;
		left: 226.37px;
		top: 143.2px;
		visibility: visible;
		transform: rotate(89.46deg);
	}
}

@keyframes ihpone02 {
	from {
		width: 215.48px;
		height: 436.79px;
		left: 226.37px;
		top: 143.2px;
		visibility: visible;
		transform: rotate(89.46deg);
	}

	to {
		width: 190.61px;
		height: 386.38px;
		left: -277.44px;
		top: 475px;

		transform: rotate(29.72deg);
		visibility: hidden;
	}
}

.animationFloor {
	background: url(/public/assets/svg/fantasy/floor.svg);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 767px;
	height: 193px;
	left: -826px;
	top: 593px;
	z-index: -2;
	visibility: hidden;
}

.animationStartFloor {
	animation: 1s floor01 1s ease-out, 1.4s floor02 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopFloor {
	animation: floor03 1.5s ease-out;
	animation-fill-mode: forwards;
}

@keyframes floor01 {
	to {
		width: 767px;
		height: 193px;
		left: -787px;
		top: 601px;
		visibility: visible;
	}
}

@keyframes floor02 {
	to {
		width: 596px;
		height: 106px;
		left: 50px;
		top: 415px;
	}
}

@keyframes floor03 {
	from {
		width: 596px;
		height: 106px;
		left: 50px;
		top: 415px;
		visibility: visible;
	}

	to {
		width: 596px;
		height: 106px;
		left: -688px;
		top: 995px;
		border-radius: 0px;
		visibility: hidden;
	}
}

.animationFlower {
	background: url(/public/assets/svg/fantasy/flower.svg);
	background-repeat: no-repeat;
	background-size: contain;
	width: 132.32px;
	position: absolute;
	height: 123.86px;
	left: 36.13px;
	top: -160px;

	filter: blur(2.5px);
	visibility: hidden;
	transform: rotate(14.55deg);
}

.animationStartFlower {
	animation: 1s flower01 1s ease-out, 1.4s flower02 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopFlower {
	animation: flower03 1.5s ease-out;
	animation-fill-mode: forwards;
}

@keyframes flower01 {
	to {
		visibility: visible;
		width: 132.32px;
		height: 123.86px;
		left: 39.32px;
		top: 516px;

		filter: blur(2.5px);
		transform: rotate(14.55deg);
	}
}

@keyframes flower02 {
	to {
		width: 105.3px;
		height: 98.57px;
		left: 131px;
		top: 285px;
		filter: blur(0px);
		transform: rotate(0deg);
	}
}

@keyframes flower03 {
	from {
		width: 105.3px;
		height: 98.57px;
		left: 131px;
		top: 285px;
		filter: blur(0px);
		transform: rotate(0deg);
		visibility: visible;
	}

	to {
		left: -504px;
		top: 585px;

		filter: blur(2.5px);
		transform: rotate(-9.92deg);
		visibility: hidden;
	}
}

.animationDrawer {
	background: url(/public/assets/svg/fantasy/drawer.svg);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 179.19px;
	height: 149.46px;
	left: 264px;
	top: -585.57px;

	filter: blur(3.5px);
	transform: rotate(-21.76deg);
	visibility: hidden;
}

.animationStartDrawer {
	animation: 1s drawer01 1s ease-out, 1.4s drawer02 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopDrawer {
	animation: drawer03 1.5s ease-out;
	animation-fill-mode: forwards;
}

@keyframes drawer01 {
	to {
		width: 179.19px;
		height: 149.46px;
		left: 267.2px;
		top: 90.43px;

		filter: blur(3.5px);
		transform: rotate(-21.76deg);
		visibility: visible;
	}
}

@keyframes drawer02 {
	to {
		width: 85.45px;
		height: 71.27px;
		left: 140.55px;
		top: 381.29px;
		filter: blur(0px);
		transform: rotate(0deg);
	}
}

@keyframes drawer03 {
	from {
		width: 85.45px;
		height: 71.27px;
		left: 140.55px;
		top: 381.29px;
		filter: blur(0px);
		transform: rotate(0deg);
		visibility: visible;
	}

	to {
		width: 85.45px;
		height: 71.27px;
		left: -208.36px;
		top: 549px;
		filter: blur(3.5px);
		transform: rotate(7.17deg);
		visibility: hidden;
	}
}

.animationCarpet {
	background: url(/public/assets/svg/fantasy/carpet.svg);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 563px;
	left: -468px;
	width: 278.18px;
	height: 31.18px;
	visibility: hidden;
}

.animationStartCarpet {
	animation: 1s carpet01 1s ease-out, 1.4s carpet02 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopCarpet {
	animation: carpet03 1.5s ease-out;
	animation-fill-mode: forwards;
}

@keyframes carpet01 {
	to {
		visibility: visible;
		left: -451px;
		top: 563px;
	}
}

@keyframes carpet02 {
	to {
		left: 239px;
		top: 476px;
	}
}

@keyframes carpet03 {
	from {
		left: 239px;
		top: 476px;
		visibility: visible;
	}

	to {
		left: -306px;
		top: 938px;
		border-radius: 0px;
		visibility: hidden;
	}
}

.animationWindow {
	background: url(/public/assets/svg/fantasy/window.svg);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	height: 129.489990234375px;
	width: 161.83245849609375px;
	left: -64px;
	top: -211px;
	border-radius: 0px;
	visibility: hidden;
}

.animationStartWindow {
	animation: 1s window01 1s ease-out, 1.4s window02 1.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopWindow {
	animation: window03 1.5s ease-out;
	animation-fill-mode: forwards;
}

@keyframes window01 {
	to {
		height: 129.489990234375px;
		width: 161.83245849609375px;
		left: -64px;
		top: -211px;
		border-radius: 0px;
		visibility: visible;
	}
}

@keyframes window02 {
	to {
		height: 129.489990234375px;
		width: 161.83245849609375px;
		left: 455px;
		top: 179px;
		border-radius: 0px;
	}
}

@keyframes window03 {
	from {
		height: 129.489990234375px;
		width: 161.83245849609375px;
		left: 455px;
		top: 179px;
		border-radius: 0px;
		visibility: visible;
	}

	to {
		height: 129.489990234375px;
		width: 161.83245849609375px;
		left: -373px;
		top: -273px;
		border-radius: 0px;
		visibility: hidden;
	}
}

.animationMan {
	background: url(/public/MainPageSliderItems/man_oculus.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 619px;
	height: 619px;
	left: 30px;
	top: -585.57px;
	visibility: hidden;
	filter: blur(3.5px);
	transform: rotate(-21.76deg);
}

.animationStartMan {
	animation: 1s man01 0.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopMan {
	animation: 1.1s man02 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes man01 {
	to {
		height: 519px;
		width: 519px;
		left: 250px;
		top: 60px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(10deg);
		visibility: visible;
	}
}

@keyframes man02 {
	from {
		height: 519px;
		width: 519px;
		left: 250px;
		top: 60px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(10deg);
		visibility: visible;
	}

	to {
		height: 519px;
		width: 519px;
		left: -550px;
		top: 500px;
		border-radius: 0px;
		visibility: hidden;
	}
}

.animationJoy2 {
	background: url(/public/MainPageSliderItems/joy2.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 130px;
	height: 150px;
	left: 480px;
	top: -585.57px;
	visibility: hidden;
	filter: blur(3.5px);
	transform: rotate(-21.76deg);
}

.animationStartJoy2 {
	animation: 1s joy2_01 0.6s ease-out, 1s joy2_02 2s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopJoy2 {
	animation: 1s joy2_03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes joy2_01 {
	to {
		height: 100px;
		width: 120px;
		left: 570px;
		top: 250px;
		border-radius: 0px;
		filter: blur(2px);
		transform: rotate(0deg);
		visibility: visible;
	}
}

@keyframes joy2_02 {
	to {
		height: 105;
		width: 125px;
		left: 500px;
		top: 400px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(-35deg);
	}
}

@keyframes joy2_03 {
	from {
		height: 105px;
		width: 125px;
		left: 500px;
		top: 400px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(-35deg);
		visibility: visible;
	}

	to {
		height: 135px;
		width: 155px;
		left: -250px;
		top: 900px;
		border-radius: 0px;
		visibility: hidden;
	}
}

.animationJoy {
	background: url(/public/MainPageSliderItems/joy.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 130px;
	height: 150px;
	left: -300px;
	top: -585.57px;
	visibility: hidden;
	filter: blur(3.5px);
	transform: rotate(-21.76deg);
}

.animationStartJoy {
	animation: 1s joy_01 0.6s ease-out, 1s joy_02 2s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopJoy {
	animation: 1s joy_03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes joy_01 {
	to {
		height: 85px;
		width: 105px;
		left: 150px;
		top: 400px;
		border-radius: 0px;
		filter: blur(03px);
		transform: rotate(-120deg);
		visibility: visible;
	}
}

@keyframes joy_02 {
	to {
		height: 95px;
		width: 115px;
		left: 250px;
		top: 300px;
		border-radius: 0px;
		filter: blur(01.5px);
		transform: rotate(-110deg);
	}
}

@keyframes joy_03 {
	from {
		height: 95px;
		width: 115px;
		left: 50px;
		top: 400px;
		border-radius: 0px;
		filter: blur(01.5px);
		transform: rotate(-110deg);
		visibility: visible;
	}

	to {
		height: 95px;
		width: 115px;
		left: -400px;
		top: 800px;
		border-radius: 0px;
		visibility: hidden;
	}
}

.animationWoman {
	background: url(/public/MainPageSliderItems/woman.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 130px;
	height: 150px;
	left: 300px;
	top: -585.57px;

	filter: blur(3.5px);
	transform: rotate(-21.76deg);
	visibility: hidden;
}

.animationStartWoman {
	animation: 1.5s Woman01 0.4s ease-out, 1.5s Woman02 2.75s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopWoman {
	animation: 1s Woman03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes Woman01 {
	to {
		height: 350px;
		width: 350px;
		left: 400px;
		top: 150px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(0deg);
		visibility: visible;
	}
}

@keyframes Woman02 {
	to {
		height: 400px;
		width: 400px;
		left: 200px;
		top: 125px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(0deg);
	}
}

@keyframes Woman03 {
	from {
		height: 400px;
		width: 400px;
		left: 200px;
		top: 125px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(0deg);
		visibility: visible;
	}

	to {
		height: 430px;
		width: 430px;
		left: -450px;
		top: 500px;
		border-radius: 0px;
		filter: blur(0px);
		transform: rotate(0deg);
		visibility: hidden;
	}
}

.animationIphone2 {
	position: absolute;
	background-repeat: no-repeat;
	width: 170px;
	height: 340px;
	left: -400px;
	top: 0px;
	background: url(/public/assets/svg/fantasy/iphone.svg);
	background-size: contain;
	transform: rotate(9.86deg);
	z-index: 2;
	visibility: hidden;
}

.animationStartIphone2 {
	animation: 1s ihpone04 1s ease-out, 1s ihpone05 3s ease-out, 1s ihpone06 4s ease-out, infinite;
	/* animation: ihpone04 1s ease-out,
    1s ihpone05 3s ease-out,
    1s ihpone06 4s ease-out,
    1.2s ihpone07 5.5s ease-out, 
    infinite; */
	animation-fill-mode: forwards;
}

.animationStopIphone2 {
	animation: 1.2s ihpone07 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes ihpone04 {
	to {
		background-repeat: no-repeat;
		width: 150px;
		height: 300px;
		left: 300px;
		top: 300px;
		visibility: visible;
		transform: rotate(30deg);
	}
}

@keyframes ihpone05 {
	to {
		background-repeat: no-repeat;
		width: 170px;
		height: 320px;
		left: 315px;
		top: 120px;

		transform: rotate(0deg);
	}
}

@keyframes ihpone06 {
	to {
		background-repeat: no-repeat;
		width: 170px;
		height: 320px;
		left: 320px;
		top: 150px;

		transform: rotate(0deg);
	}
}

@keyframes ihpone07 {
	from {
		background-repeat: no-repeat;
		width: 170px;
		height: 320px;
		left: 320px;
		top: 150px;
		transform: rotate(0deg);
		visibility: visible;
	}

	to {
		background-repeat: no-repeat;
		width: 170px;
		height: 320px;
		left: -400px;
		top: 500px;

		transform: rotate(0deg);
		visibility: hidden;
	}
}

.animationDress {
	position: absolute;
	background-repeat: no-repeat;
	width: 120px;
	height: 120px;
	left: 350px;
	top: 300px;
	background: url(/public/MainPageSliderItems/dress.png);
	background-size: contain;
	transform: rotate(9.86deg);
	z-index: 1;
	opacity: 0%;
	visibility: hidden;
}

.animationStartDress {
	animation: 1.5s dress01 1.5s ease-out, 1s dress02 3.2s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopDress {
	animation: 1s dress03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes dress01 {
	to {
		width: 330px;
		height: 330px;
		left: 210px;
		top: 270px;
		opacity: 100%;
		transform: rotate(30deg);
		visibility: visible;
	}
}

@keyframes dress02 {
	to {
		width: 385px;
		height: 385px;
		left: 205px;
		top: 128px;
		opacity: 100%;
		transform: rotate(2deg);
	}
}

@keyframes dress03 {
	from {
		width: 385px;
		height: 385px;
		left: 206px;
		top: 128px;
		opacity: 100%;
		transform: rotate(2deg);
		visibility: visible;
	}

	to {
		width: 430px;
		height: 430px;
		left: -450px;
		top: 500px;
		opacity: 100%;
		transform: rotate(2deg);
		visibility: hidden;
	}
}

.animationWatch {
	background: url(/public/MainPageSliderItems/watch.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 60px;
	height: 60px;
	left: 390px;
	top: 320px;
	opacity: 0%;
	filter: blur(0.5px);
	transform: rotate(-21.76deg);
	visibility: hidden;
}

.animationStartWatch {
	animation: 1s watch_01 1.6s ease-out, 1s watch_02 3s ease-out, 0.5s watch_03 3.8s ease-out,
		infinite;
	animation-fill-mode: forwards;
}

.animationStopWatch {
	animation: 1.1s watch_04 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes watch_01 {
	to {
		width: 140px;
		height: 140px;
		left: 330px;
		top: 400px;
		opacity: 100%;
		transform: rotate(30deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes watch_02 {
	to {
		width: 140px;
		height: 140px;
		left: 340px;
		top: 260px;
		opacity: 100%;
		transform: rotate(-10deg);
		filter: blur(0px);
	}
}

@keyframes watch_03 {
	to {
		width: 100px;
		height: 100px;
		left: 380px;
		top: 275px;
		opacity: 100%;
		transform: rotate(-15deg);
		filter: blur(0px);
	}
}

@keyframes watch_04 {
	from {
		width: 100px;
		height: 100px;
		left: 380px;
		top: 275px;
		opacity: 100%;
		transform: rotate(-15deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 110px;
		height: 110px;
		left: -380px;
		top: 620px;
		opacity: 100%;
		transform: rotate(-15deg);
		filter: blur(0px);
		visibility: hidden;
	}
}

.animationArm {
	background: url(/public/MainPageSliderItems/arm.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 400px;
	height: 400px;
	left: 450px;
	top: -500px;
	filter: blur(2.5px);
	transform: rotate(-21.76deg);
	visibility: hidden;
}

.animationStartArm {
	animation: 1s Arm_01 1s ease-out, 1s Arm_02 3s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopArm {
	animation: 1s Arm_03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes Arm_01 {
	to {
		width: 330px;
		height: 330px;
		left: 480px;
		top: 250px;
		transform: rotate(30deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes Arm_02 {
	to {
		width: 400px;
		height: 400px;
		left: 280px;
		top: 200px;
		transform: rotate(75deg);
		filter: blur(0px);
	}
}

@keyframes Arm_03 {
	from {
		width: 400px;
		height: 400px;
		left: 280px;
		top: 200px;
		transform: rotate(75deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 400px;
		height: 400px;
		left: -400px;
		top: 500px;
		transform: rotate(75deg);
		filter: blur(0px);
		visibility: hidden;
	}
}

.animationFoot {
	background: url(/public/MainPageSliderItems/foot.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 400px;
	height: 400px;
	left: 450px;
	top: -500px;
	filter: blur(2.5px);
	transform: rotate(-40deg);
	visibility: hidden;
}

.animationStartFoot {
	animation: 1s Foot_01 1s ease-out, 1s Foot_02 3s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopFoot {
	animation: 1s Foot_03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes Foot_01 {
	to {
		width: 230px;
		height: 230px;
		left: 480px;
		top: 100px;
		transform: rotate(20deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes Foot_02 {
	to {
		width: 270px;
		height: 270px;
		left: 250px;
		top: 165px;
		transform: rotate(-15deg);
		filter: blur(0px);
	}
}

@keyframes Foot_03 {
	from {
		width: 270px;
		height: 270px;
		left: 250px;
		top: 165px;
		transform: rotate(-15deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 270px;
		height: 270px;
		left: -440px;
		top: 520px;
		transform: rotate(-15deg);
		filter: blur(0px);
		visibility: hidden;
	}
}

.animationSneaker {
	background: url(/public/MainPageSliderItems/shoe.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 60px;
	height: 60px;
	left: 390px;
	top: 320px;
	opacity: 0%;
	filter: blur(0.5px);
	transform: rotate(-21.76deg);
	visibility: hidden;
}

.animationStartSneaker {
	animation: 1s Sneaker_01 1s ease-out, 1s Sneaker_02 3s ease-out, 1s Sneaker_03 4s ease-out,
		infinite;
	animation-fill-mode: forwards;
}

.animationStopSneaker {
	animation: 1s Sneaker_04 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes Sneaker_01 {
	to {
		width: 120px;
		height: 120px;
		left: 330px;
		top: 380px;
		opacity: 100%;
		transform: rotate(20deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes Sneaker_02 {
	to {
		width: 120px;
		height: 120px;
		left: 315px;
		top: 250px;
		opacity: 100%;
		transform: rotate(5deg);
		filter: blur(0px);
	}
}

@keyframes Sneaker_03 {
	to {
		width: 150px;
		height: 150px;
		left: 325px;
		top: 350px;
		opacity: 100%;
		transform: rotate(-6deg);
		filter: blur(0px);
	}
}

@keyframes Sneaker_04 {
	from {
		width: 150px;
		height: 150px;
		left: 325px;
		top: 350px;
		opacity: 100%;
		transform: rotate(-6deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 150px;
		height: 150px;
		left: -350px;
		top: 700px;
		opacity: 100%;
		transform: rotate(2deg);
		visibility: hidden;
	}
}

.animationCorner {
	background: url(/public/MainPageSliderItems/corner.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 200px;
	height: 200px;
	left: 450px;
	top: -500px;
	filter: blur(2.5px);
	transform: rotate(-40deg);
	visibility: hidden;
}

.animationStartCorner {
	animation: 1s corner_01 0.3s ease-out, 1s corner_02 2.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopCorner {
	animation: 1.6s corner_03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes corner_01 {
	to {
		width: 180px;
		height: 180px;
		left: 450px;
		top: 150px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes corner_02 {
	to {
		width: 180px;
		height: 180px;
		left: 400px;
		top: 200px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
	}
}

@keyframes corner_03 {
	from {
		width: 180px;
		height: 180px;
		left: 400px;
		top: 200px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 180px;
		height: 180px;
		left: -350px;
		top: 600px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: hidden;
	}
}

.animationJoint {
	background: url(/public/MainPageSliderItems/joint.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 200px;
	height: 200px;
	left: 350px;
	top: -500px;
	filter: blur(2.5px);
	transform: rotate(-40deg);
	visibility: hidden;
}

.animationStartJoint {
	animation: 1s joint_01 0.6s ease-out, 0.2s joint_02 2s ease-out, 0.2s joint_01 2.3s ease-out,
		1s joint_03 2.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopJoint {
	animation: 1.5s joint_04 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes joint_01 {
	to {
		width: 50px;
		height: 50px;
		left: 460px;
		top: 220px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes joint_02 {
	to {
		width: 60px;
		height: 60px;
		left: 460px;
		top: 220px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		filter: invert(60%);
	}
}

@keyframes joint_03 {
	to {
		width: 50px;
		height: 50px;
		left: 420px;
		top: 260px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
	}
}

@keyframes joint_04 {
	from {
		width: 50px;
		height: 50px;
		left: 420px;
		top: 260px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 50px;
		height: 50px;
		left: -270px;
		top: 650px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: hidden;
	}
}

.animationLeftSofa {
	background: url(/public/MainPageSliderItems/leftsofa.png);
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	width: 200px;
	height: 200px;
	left: 250px;
	top: -500px;
	filter: blur(2.5px);
	transform: rotate(-40deg);
	visibility: hidden;
}

.animationStartLeftSofa {
	animation: 1s leftSofa_01 0.4s ease-out, 1s leftSofa_02 2.5s ease-out, infinite;
	animation-fill-mode: forwards;
}

.animationStopLeftSofa {
	animation: 1s leftSofa_03 ease-out, infinite;
	animation-fill-mode: forwards;
}

@keyframes leftSofa_01 {
	to {
		width: 180px;
		height: 180px;
		left: 200px;
		top: 220px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: visible;
	}
}

@keyframes leftSofa_02 {
	to {
		width: 190px;
		height: 190px;
		left: 288px;
		top: 223px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
	}
}

@keyframes leftSofa_03 {
	from {
		width: 190px;
		height: 190px;
		left: 288px;
		top: 223px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: visible;
	}

	to {
		width: 190px;
		height: 190px;
		left: -238px;
		top: 500px;
		opacity: 100%;
		transform: rotate(0deg);
		filter: blur(0px);
		visibility: hidden;
	}
}

@keyframes slideRight {
	from {
		transform: translateX(100%);
		visibility: hidden;
	}

	to {
		transform: translateX(0);
		visibility: visible;
	}
}

.slideDetailFirstSpan {
	width: 80%;
	font-style: normal;
	font-weight: 700;
	font-size: 42px;
	line-height: 52px;

	color: #000000;
}

.slideDetailElements {
	display: flex;
	position: relative;
	flex-direction: column;
	gap: 30px;
	align-items: flex-start;
}

.slideDetailSecondSpan {
	width: 100%;
	font-style: normal;
	font-weight: 300;
	font-size: 19px;
	line-height: 32px;
	color: #000000;
}

.slideDetailButton {
	border: none;
	height: 44px;
	background: #ed401a;
	border-radius: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	padding: 0px 20px;
}

.slideDetailButton a {
	width: 120px;
	height: 18px;
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 18px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
}

.tablet .slideDetail {
	width: 90%;
	gap: 20px;
	bottom: 3vh;

	.slideDetailElements {
		gap: 20px;
		width: 95%;	
	}
	.slideDetailFirstSpan {
		font-size: 28px;
		line-height: 35px;
	}
	.slideDetailSecondSpan {
		font-size: 16px;
		line-height: 20px;
	}
}

.tablet .smallSliderMenu {
	width: 54px;
	height: calc(30% + 5 * 10px);
	top: 10%;
	right: 5%;
	left: unset;
	bottom: auto;
	padding: 15px 5px;
	flex-direction: column;
}


.tablet .mainPageTopRightFigure {
	display: none;
}

.tablet .wheelAnimationItemInner {
	bottom: 7vh;
	right: 0;
}

.mobile .slideDetail {
	bottom: 3.5vh;
	padding: 20px 0 20px 20px;
	width: 90%;
}
.mobile .slideDetailElements {
	gap: 10px;
}

.mobile .smallSliderMenu {
	width: 54px;
	height: calc(30% + 50px);
	min-height: 270px;
	top: 2%;
	right: 5%;
	left: unset;
	bottom: auto;
	padding: 15px 5px;
	flex-direction: column;
}


.mobile .slideDetailFirstSpan {
	width: 80% !important;
	font-size: 24px !important;
	line-height: 35px !important;
}

.mobile .slideDetailSecondSpan {
	font-size: 16px;
	line-height: 24px;
}

.mobile .slideDetailButton {
	height: 35px;
}

.mobile .slideDetailButton a {
	width: 120px;
	height: 14px;
	font-size: 10px;
}

.mobile .wheelAnimationItemInner {
	right: 10vh;
}

.mobile .mainPageSmallFigure {
	display: flex;
	left: -300px !important;
}

.mobile .mainPageMiddleFigure {
	left: calc(-20%);
	top: calc(30%);
}

.mobile .mainPageTopRightFigure {
	display: none;
}

.mobile .mainPageTopLeftFigure {
	display: none;
}

/* Animation Tablet screen */
/* 
@media screen and (min-width:700) and (max-width:1000px){
  

  @keyframes sofa0 {
    to {
      visibility: visible;
      width: 30vw;
      height: 30vw;
      left: 65vw;
      top: 45vw;
    }
  }
  
  @keyframes sofa1 {
    to {
      width: 45vw;
      height: 45vw;
      left: 65vw;
      top: 55vw;
    }
  }
  
  @keyframes sofa2 {
    from {
      width: 45vw;
      height: 45vw;
      left: 65vw;
      top: 55vw;
      visibility: visible;
    }
  
    to {
      width: 40%;
      height: 40%;
      left: -347px;
      top: 780px;
      visibility: hidden;
    }
  }
  
  
  @keyframes ihpone0 {
    to {
      width: 35vw;
      height: 70vw;
      left: 65vw;
      top: 27vw;
      transform: rotate(105.22deg);
      visibility: visible;
  
    }
  }
  
  
  @keyframes ihpone01 {
    to {
      width: 35vw;
      height: 70vw;
      left: 65vw;
      top: 27vw;
      visibility: visible;
      transform: rotate(89.46deg);
    }
  }
  
  @keyframes ihpone02 {
    from {
      width: 35vw;
      height: 70vw;
      left: 65vw;
      top: 27vw;
      visibility: visible;
      transform: rotate(89.46deg);
    }
  
    to {
      width: 32%;
      height: 65%;
      left: -277.44px;
      top: 475px;
  
      transform: rotate(29.72deg);
      visibility: hidden;
    }
  }
  
  @keyframes floor01 {
    to {
      width: 70vw;
      height: 70vw;
      left: 50vw;
      top: 75vw;
      visibility: visible;
    }
  }
  
  @keyframes floor02 {
    to {
      width: 70vw;
      height: 70vw;
      left: 50vw;
      top: 75vw;
    }
  }
  
  @keyframes floor03 {
    from {
      width: 70vw;
      height: 70vw;
      left: 50vw;
      top: 75vw;
      visibility: visible;
    }
  
    to {
      width: 70%;
      height: 70%;
      left: -688px;
      top: 995px;
      border-radius: 0px;
      visibility: hidden;
    }
  }
  

  
  @keyframes flower01 {
    to {
      visibility: visible;
      width: 18vw;
      height: 18vw;
      left: 45vw;
      top: 20vw;
  
      filter: blur(2.5px);
      transform: rotate(14.55deg);
    }
  }
  
  @keyframes flower02 {
    to {
      width: 18vw;
      height: 18vw;
      left: 48vw;
      top: 49vw;
      filter: blur(0px);
      transform: rotate(0deg);
    }
  }
  
  @keyframes flower03 {
    from {
      width: 18vw;
      height: 18vw;
      left: 48vw;
      top: 49vw;
      filter: blur(0px);
      transform: rotate(0deg);
      visibility: visible;
    }
  
    to {
      left: -504px;
      top: 585px;
  
      filter: blur(2.5px);
      transform: rotate(-9.92deg);
      visibility: hidden;
    }
  }
  
  
  @keyframes drawer01 {
    to {
      width: 16vw;
      height: 16vw;
      left: 50vw;
      top: 66vw;
  
      filter: blur(3.5px);
      transform: rotate(-21.76deg);
      visibility: visible;
    }
  }
  
  @keyframes drawer02 {
    to {
      width: 16vw;
      height: 16vw;
      left: 50vw;
      top: 66vw;
      filter: blur(0px);
      transform: rotate(0deg);
    }
  }
  
  @keyframes drawer03 {
    from {
      width: 16vw;
      height: 16vw;
      left: 50vw;
      top: 66vw;
      filter: blur(0px);
      transform: rotate(0deg);
      visibility: visible;
    }
  
    to {
      width: 20%;
      height: 20%;
      left: -208.36px;
      top: 549px;
      filter: blur(3.5px);
      transform: rotate(7.17deg);
      visibility: hidden;
    }
  }
  
  
  @keyframes carpet01 {
    to {
      visibility: visible;
      width: 60vw;
      left: 55vw;
      top: 70vw;
    }
  }
  
  @keyframes carpet02 {
    to {
      width: 60vw;
      left: 55vw;
      top: 78vw;
    }
  }
  
  @keyframes carpet03 {
    from {
      width: 60vw;
      left: 55vw;
      top: 78vw;
      visibility: visible;
    }
  
    to {
      left: -306px;
      top: 938px;
      border-radius: 0px;
      visibility: hidden;
    }
  }
  
 
  
  @keyframes window01 {
    to {
      height: 30vw;
      width: 40vw;
      left: 50vw;
      top: 20vw;
      border-radius: 0px;
      visibility: visible;
    }
  }
  
  @keyframes window02 {
    to {
      height: 30vw;
      width: 30vw;
      left: 90vw;
      top: 33vw;
      border-radius: 0px;
    }
  }
  
  @keyframes window03 {
    from {
      height: 30vw;
      width: 30vw;
      left: 90vw;
      top: 33vw;
      border-radius: 0px;
      visibility: visible;
    }
  
    to {
      height: 129.489990234375px;
      width: 161.83245849609375px;
      left: -373px;
      top: -273px;
      border-radius: 0px;
      visibility: hidden;
    }
  }
  
 
  
  @keyframes man01 {
    to {
      height: 90vw;
      width: 90vw;
      left: 42vw;
      top: 27vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(10deg);
      visibility: visible;
    }
  }
  
  @keyframes man02 {
    from {
      height: 90vw;
      width: 90vw;
      left: 42vw;
      top: 27vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(10deg);
      visibility: visible;
    }
  
    to {
      height: 519px;
      width: 519px;
      left: -550px;
      top: 500px;
      border-radius: 0px;
      visibility: hidden;
    }
  }
  
  
  @keyframes joy2_01 {
    to {
      height: 25vw;
      width: 35vw;
      left: 100vw;
      top: 50vw;
      border-radius: 0px;
      filter: blur(2px);
      transform: rotate(0deg);
      visibility: visible;
    }
  }
  
  @keyframes joy2_02 {
    to {
      height: 20vw;
      width: 30vw;
      left: 83vw;
      top: 82vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(-35deg);
    }
  }
  
  @keyframes joy2_03 {
    from {
      height: 20vw;
      width: 30vw;
      left: 83vw;
      top: 82vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(-35deg);
      visibility: visible;
  
    }
  
    to {
      height: 135px;
      width: 155px;
      left: -250px;
      top: 900px;
      border-radius: 0px;
      visibility: hidden;
    }
  }
  
  
  @keyframes joy_01 {
    to {
      height: 15vw;
      width: 25vw;
      left: 35vw;
      top: 30vw;
      border-radius: 0px;
      filter: blur(03px);
      transform: rotate(-120deg);
      visibility: visible;
    }
  }
  
  @keyframes joy_02 {
    to {
      height: 15vw;
      width: 25vw;
      left: 40vw;
      top: 50vw;
      border-radius: 0px;
      filter: blur(01.5px);
      transform: rotate(-110deg);
    }
  }
  
  @keyframes joy_03 {
    from {
      height: 15vw;
      width: 25vw;
      left: 40vw;
      top: 50vw;
      border-radius: 0px;
      filter: blur(01.5px);
      transform: rotate(-110deg);
      visibility: visible;
  
    }
  
    to {
      height: 95px;
      width: 115px;
      left: -400px;
      top: 800px;
      border-radius: 0px;
      visibility: hidden;
    }
  }
  
  
  @keyframes Woman01 {
    to {
      height: 50vw;
      width: 50vw;
      left: 80vw;
      top: 40vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(0deg);
      visibility: visible;
    }
  }
  
  @keyframes Woman02 {
    to {
      height: 65vw;
      width: 65vw;
      left: 50vw;
      top: 35vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(0deg);
    }
  }
  
  @keyframes Woman03 {
    from {
      height: 65vw;
      width: 65vw;
      left: 50vw;
      top: 35vw;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(0deg);
      visibility: visible;
  
    }
  
    to {
      height: 430px;
      width: 430px;
      left: -450px;
      top: 500px;
      border-radius: 0px;
      filter: blur(0px);
      transform: rotate(0deg);
      visibility: hidden;
    }
  }
  
  
  @keyframes ihpone04 {
    to {
      background-repeat: no-repeat;
      width: 30vw;
      height: 60vw;
      left: 70vw;
      top: 50vw;
      visibility: visible;
      transform: rotate(30deg);
    }
  }
  
  @keyframes ihpone05 {
    to {
      background-repeat: no-repeat;
      width: 35vw;
      height: 70vw;
      left: 65vw;
      top: 30vw;
  
      transform: rotate(0deg);
    }
  }
  
  @keyframes ihpone06 {
    to {
      background-repeat: no-repeat;
      width: 35vw;
      height: 70vw;
      left: 65vw;
      top: 40vw;
  
      transform: rotate(0deg);
    }
  }
  
  @keyframes ihpone07 {
    from {
      background-repeat: no-repeat;
      width: 35vw;
      height: 70vw;
      left: 65vw;
      top: 40vw;
      transform: rotate(0deg);
      visibility: visible;
    
  }
  
    to {
      background-repeat: no-repeat;
      width: 170px;
      height: 320px;
      left: -400px;
      top: 500px;
  
      transform: rotate(0deg);
      visibility: hidden;
    }
  }
  
  @keyframes dress01 {
    to {
      width: 60vw;
      height: 60vw;
      left: 55vw;
      top: 50vw;
      opacity: 100%;
      transform: rotate(30deg);
      visibility: visible;
    }
  }
  
  @keyframes dress02 {
    to {
      width: 85vw;
      height: 85vw;
      left: 39vw;
      top: 30vw;
      opacity: 100%;
      transform: rotate(2deg);
    }
  }
  
  @keyframes dress03 {
    from {
      width: 85vw;
      height: 85vw;
      left: 39vw;
      top: 30vw;
      opacity: 100%;
      transform: rotate(2deg);
      visibility: visible;
  
    }
  
    to {
      width: 430px;
      height: 430px;
      left: -450px;
      top: 500px;
      opacity: 100%;
      transform: rotate(2deg);
      visibility: hidden;
    }
  }
  

  
  @keyframes watch_01 {
    to {
      width: 32vw;
      height: 32vw;
      left: 70vw;
      top: 75vw;
      opacity: 100%;
      transform: rotate(30deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes watch_02 {
    to {
      width: 35vw;
      height: 35vw;
      left: 70vw;
      top: 45vw;
      opacity: 100%;
      transform: rotate(-10deg);
      filter: blur(0px);
    }
  }
  
  @keyframes watch_03 {
    to {
      width: 25vw;
      height: 25vw;
      left: 73vw;
      top: 63vw;
      opacity: 100%;
      transform: rotate(-15deg);
      filter: blur(0px);
    }
  }
  
  @keyframes watch_04 {
    from {
      width: 25vw;
      height: 25vw;
      left: 73vw;
      top: 63vw;
      opacity: 100%;
      transform: rotate(-15deg);
      filter: blur(0px);
      visibility: visible;
  
    }
  
    to {
      width: 110px;
      height: 110px;
      left: -380px;
      top: 620px;
      opacity: 100%;
      transform: rotate(-15deg);
      filter: blur(0px);
      visibility: hidden;
  
    }
  }
  
  
  @keyframes Arm_01 {
    to {
      width: 60vw;
      height: 60vw;
      left: 90vw;
      top: 50vw;
      transform: rotate(30deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes Arm_02 {
    to {
      width: 80vw;
      height: 80vw;
      left: 55vw;
      top: 50vw;
      transform: rotate(75deg);
      filter: blur(0px);
    }
  }
  
  @keyframes Arm_03 {
    from {
      width: 80vw;
      height: 80vw;
      left: 55vw;
      top: 50vw;
      transform: rotate(75deg);
      filter: blur(0px);
      visibility: visible;
  
    }
  
    to {
      width: 400px;
      height: 400px;
      left: -400px;
      top: 500px;
      transform: rotate(75deg);
      filter: blur(0px);
      visibility: hidden;
  
    }
  }
  
  
  @keyframes Foot_01 {
    to {
      width: 50vw;
      height: 50vw;
      left: 95vw;
      top: 45vw;
      transform: rotate(20deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes Foot_02 {
    to {
      width: 55vw;
      height: 55vw;
      left: 53vw;
      top: 40vw;
      transform: rotate(-15deg);
      filter: blur(0px);
    }
  }
  
  @keyframes Foot_03 {
    from {
      width: 50vw;
      height: 50vw;
      left: 53vw;
      top: 40vw;
      transform: rotate(-15deg);
      filter: blur(0px);    
      visibility: visible;
  
    }
  
    to {
      width: 270px;
      height: 270px;
      left: -440px;
      top: 520px;
      transform: rotate(-15deg);
      filter: blur(0px);
      visibility: hidden;
  
    }
  }
  
  
  @keyframes Sneaker_01 {
    to {
      width: 35vw;
      height: 35vw;
      left: 50vw;
      top: 50vw;
      opacity: 100%;
      transform: rotate(30deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes Sneaker_02 {
    to {
      width: 25vw;
      height: 25vw;
      left: 70vw;
      top: 60vw;
      opacity: 100%;
      transform: rotate(5deg);
      filter: blur(0px);
    }
  }
  
  @keyframes Sneaker_03 {
    to {
      width: 32vw;
      height: 32vw;
      left: 68vw;
      top: 76vw;
      opacity: 100%;
      transform: rotate(-6deg);
      filter: blur(0px);
    }
  }
  
  @keyframes Sneaker_04 {
    from {
      width: 32vw;
      height: 32vw;
      left: 68vw;
      top: 76vw;
      opacity: 100%;
      transform: rotate(-6deg);
      filter: blur(0px);
      visibility: visible;
  
    }
  
    to {
      width: 150px;
      height: 150px;
      left: -350px;
      top: 700px;
      opacity: 100%;
      transform: rotate(2deg);
      visibility: hidden;
    }
  }
  
  
  @keyframes corner_01 {
    to {
      width: 38vw;
      height: 38vw;
      left: 85vw;
      top: 30vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes corner_02 {
    to {
      width: 38vw;
      height: 38vw;
      left: 80vw;
      top: 50vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
    }
  }
  
  @keyframes corner_03 {
    from {
      width: 38vw;
      height: 38vw;
      left: 80vw;
      top: 50vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: visible;
  
    }
  
    to {
      width: 180px;
      height: 180px;
      left: -350px;
      top: 600px;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: hidden;
  
    }
  }
  
  
  @keyframes joint_01 {
    to {
      width: 15vw;
      height: 15vw;
      left: 80vw;
      top: 50vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes joint_02 {
    to {
      width: 20vw;
      height: 20vw;
      left: 80vw;
      top: 50vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      filter: invert(60%);
      
  
    }
  }
  
  @keyframes joint_03 {
    to {
      width: 15vw;
      height: 15vw;
      left: 80vw;
      top: 60vw;
      opacity: 70%;
      transform: rotate(0deg);
      filter: blur(0px);
    }
  }
  
  @keyframes joint_04 {
    from {
      width: 15vw;
      height: 15vw;
      left: 80vw;
      top: 60vw;
      opacity: 70%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: visible;
  
    }
  
    to {
      width: 50px;
      height: 50px;
      left: -270px;
      top: 650px;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: hidden;
  
    }
  }
  
  @keyframes leftSofa_01 {
    to {
      width: 40vw;
      height: 40vw;
      left: 50vw;
      top: 60vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: visible;
    }
  }
  
  @keyframes leftSofa_02 {
    to {
      width: 40vw;
      height: 40vw;
      left: 56.5vw;
      top: 54.5vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
    }
  }
  
  @keyframes leftSofa_03 {
    from {
      width: 40vw;
      height: 40vw;
      left: 56.5vw;
      top: 54.5vw;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: visible;
  
    }
  
    to {
      width: 190px;
      height: 190px;
      left: -238px;
      top: 500px;
      opacity: 100%;
      transform: rotate(0deg);
      filter: blur(0px);
      visibility: hidden;
  
    }
  }
} */

/* Animation Mobile screen */
@media screen and (max-width: 700px) {
	/*
	 *    ANIMATION 1
	 */
	@keyframes sofa0 {
		to {
			visibility: visible;
			width: 30vw;
			height: 30vw;
			left: 65vw;
			top: 45vw;
		}
	}

	@keyframes sofa1 {
		to {
			width: 45vw;
			height: 45vw;
			left: 65vw;
			top: 55vw;
		}
	}

	@keyframes sofa2 {
		from {
			width: 45vw;
			height: 45vw;
			left: 65vw;
			top: 55vw;
			visibility: visible;
		}

		to {
			width: 40%;
			height: 40%;
			left: -347px;
			top: 780px;
			visibility: hidden;
		}
	}

	@keyframes ihpone0 {
		to {
			width: 35vw;
			height: 70vw;
			left: 65vw;
			top: 27vw;
			transform: rotate(105.22deg);
			visibility: visible;
		}
	}

	@keyframes ihpone01 {
		to {
			width: 35vw;
			height: 70vw;
			left: 65vw;
			top: 27vw;
			visibility: visible;
			transform: rotate(89.46deg);
		}
	}

	@keyframes ihpone02 {
		from {
			width: 35vw;
			height: 70vw;
			left: 65vw;
			top: 27vw;
			visibility: visible;
			transform: rotate(89.46deg);
		}

		to {
			width: 32%;
			height: 65%;
			left: -277.44px;
			top: 475px;

			transform: rotate(29.72deg);
			visibility: hidden;
		}
	}

	@keyframes floor01 {
		to {
			width: 70vw;
			height: 70vw;
			left: 50vw;
			top: 75vw;
			visibility: visible;
		}
	}

	@keyframes floor02 {
		to {
			width: 70vw;
			height: 70vw;
			left: 50vw;
			top: 75vw;
		}
	}

	@keyframes floor03 {
		from {
			width: 70vw;
			height: 70vw;
			left: 50vw;
			top: 75vw;
			visibility: visible;
		}

		to {
			width: 70%;
			height: 70%;
			left: -688px;
			top: 995px;
			border-radius: 0px;
			visibility: hidden;
		}
	}

	@keyframes flower01 {
		to {
			visibility: visible;
			width: 18vw;
			height: 18vw;
			left: 45vw;
			top: 20vw;

			filter: blur(2.5px);
			transform: rotate(14.55deg);
		}
	}

	@keyframes flower02 {
		to {
			width: 18vw;
			height: 18vw;
			left: 48vw;
			top: 49vw;
			filter: blur(0px);
			transform: rotate(0deg);
		}
	}

	@keyframes flower03 {
		from {
			width: 18vw;
			height: 18vw;
			left: 48vw;
			top: 49vw;
			filter: blur(0px);
			transform: rotate(0deg);
			visibility: visible;
		}

		to {
			left: -504px;
			top: 585px;

			filter: blur(2.5px);
			transform: rotate(-9.92deg);
			visibility: hidden;
		}
	}

	@keyframes drawer01 {
		to {
			width: 16vw;
			height: 16vw;
			left: 50vw;
			top: 66vw;

			filter: blur(3.5px);
			transform: rotate(-21.76deg);
			visibility: visible;
		}
	}

	@keyframes drawer02 {
		to {
			width: 16vw;
			height: 16vw;
			left: 50vw;
			top: 66vw;
			filter: blur(0px);
			transform: rotate(0deg);
		}
	}

	@keyframes drawer03 {
		from {
			width: 16vw;
			height: 16vw;
			left: 50vw;
			top: 66vw;
			filter: blur(0px);
			transform: rotate(0deg);
			visibility: visible;
		}

		to {
			width: 20%;
			height: 20%;
			left: -208.36px;
			top: 549px;
			filter: blur(3.5px);
			transform: rotate(7.17deg);
			visibility: hidden;
		}
	}

	@keyframes carpet01 {
		to {
			visibility: visible;
			width: 60vw;
			left: 55vw;
			top: 70vw;
		}
	}

	@keyframes carpet02 {
		to {
			width: 60vw;
			left: 55vw;
			top: 78vw;
		}
	}

	@keyframes carpet03 {
		from {
			width: 60vw;
			left: 55vw;
			top: 78vw;
			visibility: visible;
		}

		to {
			left: -306px;
			top: 938px;
			border-radius: 0px;
			visibility: hidden;
		}
	}

	@keyframes window01 {
		to {
			height: 30vw;
			width: 40vw;
			left: 50vw;
			top: 20vw;
			border-radius: 0px;
			visibility: visible;
		}
	}

	@keyframes window02 {
		to {
			height: 30vw;
			width: 30vw;
			left: 90vw;
			top: 33vw;
			border-radius: 0px;
		}
	}

	@keyframes window03 {
		from {
			height: 30vw;
			width: 30vw;
			left: 90vw;
			top: 33vw;
			border-radius: 0px;
			visibility: visible;
		}

		to {
			height: 129.489990234375px;
			width: 161.83245849609375px;
			left: -373px;
			top: -273px;
			border-radius: 0px;
			visibility: hidden;
		}
	}

	/*
	 *    ANIMATION 2
	 */
	@keyframes man01 {
		to {
			height: 90vw;
			width: 90vw;
			left: 42vw;
			top: 27vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(10deg);
			visibility: visible;
		}
	}

	@keyframes man02 {
		from {
			height: 90vw;
			width: 90vw;
			left: 42vw;
			top: 27vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(10deg);
			visibility: visible;
		}

		to {
			height: 519px;
			width: 519px;
			left: -550px;
			top: 500px;
			border-radius: 0px;
			visibility: hidden;
		}
	}

	@keyframes joy2_01 {
		to {
			height: 25vw;
			width: 35vw;
			left: 100vw;
			top: 50vw;
			border-radius: 0px;
			filter: blur(2px);
			transform: rotate(0deg);
			visibility: visible;
		}
	}

	@keyframes joy2_02 {
		to {
			height: 20vw;
			width: 30vw;
			left: 83vw;
			top: 82vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(-35deg);
		}
	}

	@keyframes joy2_03 {
		from {
			height: 20vw;
			width: 30vw;
			left: 83vw;
			top: 82vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(-35deg);
			visibility: visible;
		}

		to {
			height: 135px;
			width: 155px;
			left: -250px;
			top: 900px;
			border-radius: 0px;
			visibility: hidden;
		}
	}

	@keyframes joy_01 {
		to {
			height: 15vw;
			width: 25vw;
			left: 35vw;
			top: 30vw;
			border-radius: 0px;
			filter: blur(03px);
			transform: rotate(-120deg);
			visibility: visible;
		}
	}

	@keyframes joy_02 {
		to {
			height: 15vw;
			width: 25vw;
			left: 40vw;
			top: 50vw;
			border-radius: 0px;
			filter: blur(01.5px);
			transform: rotate(-110deg);
		}
	}

	@keyframes joy_03 {
		from {
			height: 15vw;
			width: 25vw;
			left: 40vw;
			top: 50vw;
			border-radius: 0px;
			filter: blur(01.5px);
			transform: rotate(-110deg);
			visibility: visible;
		}

		to {
			height: 95px;
			width: 115px;
			left: -400px;
			top: 800px;
			border-radius: 0px;
			visibility: hidden;
		}
	}

	/*
	 *    ANIMATION 3
	 */
	@keyframes Woman01 {
		to {
			height: 50vw;
			width: 50vw;
			left: 80vw;
			top: 40vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(0deg);
			visibility: visible;
		}
	}

	@keyframes Woman02 {
		to {
			height: 65vw;
			width: 65vw;
			left: 50vw;
			top: 35vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(0deg);
		}
	}

	@keyframes Woman03 {
		from {
			height: 65vw;
			width: 65vw;
			left: 50vw;
			top: 35vw;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(0deg);
			visibility: visible;
		}

		to {
			height: 430px;
			width: 430px;
			left: -450px;
			top: 500px;
			border-radius: 0px;
			filter: blur(0px);
			transform: rotate(0deg);
			visibility: hidden;
		}
	}

	@keyframes ihpone04 {
		to {
			background-repeat: no-repeat;
			width: 30vw;
			height: 60vw;
			left: 70vw;
			top: 50vw;
			visibility: visible;
			transform: rotate(30deg);
		}
	}

	@keyframes ihpone05 {
		to {
			background-repeat: no-repeat;
			width: 35vw;
			height: 70vw;
			left: 65vw;
			top: 30vw;

			transform: rotate(0deg);
		}
	}

	@keyframes ihpone06 {
		to {
			background-repeat: no-repeat;
			width: 35vw;
			height: 70vw;
			left: 65vw;
			top: 40vw;

			transform: rotate(0deg);
		}
	}

	@keyframes ihpone07 {
		from {
			background-repeat: no-repeat;
			width: 35vw;
			height: 70vw;
			left: 65vw;
			top: 40vw;
			transform: rotate(0deg);
			visibility: visible;
		}

		to {
			background-repeat: no-repeat;
			width: 170px;
			height: 320px;
			left: -400px;
			top: 500px;

			transform: rotate(0deg);
			visibility: hidden;
		}
	}

	@keyframes dress01 {
		to {
			width: 60vw;
			height: 60vw;
			left: 55vw;
			top: 50vw;
			opacity: 100%;
			transform: rotate(30deg);
			visibility: visible;
		}
	}

	@keyframes dress02 {
		to {
			width: 85vw;
			height: 85vw;
			left: 39vw;
			top: 30vw;
			opacity: 100%;
			transform: rotate(2deg);
		}
	}

	@keyframes dress03 {
		from {
			width: 85vw;
			height: 85vw;
			left: 39vw;
			top: 30vw;
			opacity: 100%;
			transform: rotate(2deg);
			visibility: visible;
		}

		to {
			width: 430px;
			height: 430px;
			left: -450px;
			top: 500px;
			opacity: 100%;
			transform: rotate(2deg);
			visibility: hidden;
		}
	}

	@keyframes watch_01 {
		to {
			width: 32vw;
			height: 32vw;
			left: 70vw;
			top: 75vw;
			opacity: 100%;
			transform: rotate(30deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes watch_02 {
		to {
			width: 35vw;
			height: 35vw;
			left: 70vw;
			top: 45vw;
			opacity: 100%;
			transform: rotate(-10deg);
			filter: blur(0px);
		}
	}

	@keyframes watch_03 {
		to {
			width: 25vw;
			height: 25vw;
			left: 73vw;
			top: 63vw;
			opacity: 100%;
			transform: rotate(-15deg);
			filter: blur(0px);
		}
	}

	@keyframes watch_04 {
		from {
			width: 25vw;
			height: 25vw;
			left: 73vw;
			top: 63vw;
			opacity: 100%;
			transform: rotate(-15deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 110px;
			height: 110px;
			left: -380px;
			top: 620px;
			opacity: 100%;
			transform: rotate(-15deg);
			filter: blur(0px);
			visibility: hidden;
		}
	}

	@keyframes Arm_01 {
		to {
			width: 60vw;
			height: 60vw;
			left: 90vw;
			top: 50vw;
			transform: rotate(30deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes Arm_02 {
		to {
			width: 80vw;
			height: 80vw;
			left: 55vw;
			top: 50vw;
			transform: rotate(75deg);
			filter: blur(0px);
		}
	}

	@keyframes Arm_03 {
		from {
			width: 80vw;
			height: 80vw;
			left: 55vw;
			top: 50vw;
			transform: rotate(75deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 400px;
			height: 400px;
			left: -400px;
			top: 500px;
			transform: rotate(75deg);
			filter: blur(0px);
			visibility: hidden;
		}
	}

	@keyframes Foot_01 {
		to {
			width: 50vw;
			height: 50vw;
			left: 95vw;
			top: 45vw;
			transform: rotate(20deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes Foot_02 {
		to {
			width: 55vw;
			height: 55vw;
			left: 53vw;
			top: 40vw;
			transform: rotate(-15deg);
			filter: blur(0px);
		}
	}

	@keyframes Foot_03 {
		from {
			width: 50vw;
			height: 50vw;
			left: 53vw;
			top: 40vw;
			transform: rotate(-15deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 270px;
			height: 270px;
			left: -440px;
			top: 520px;
			transform: rotate(-15deg);
			filter: blur(0px);
			visibility: hidden;
		}
	}

	@keyframes Sneaker_01 {
		to {
			width: 35vw;
			height: 35vw;
			left: 50vw;
			top: 50vw;
			opacity: 100%;
			transform: rotate(30deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes Sneaker_02 {
		to {
			width: 25vw;
			height: 25vw;
			left: 70vw;
			top: 60vw;
			opacity: 100%;
			transform: rotate(5deg);
			filter: blur(0px);
		}
	}

	@keyframes Sneaker_03 {
		to {
			width: 32vw;
			height: 32vw;
			left: 68vw;
			top: 76vw;
			opacity: 100%;
			transform: rotate(-6deg);
			filter: blur(0px);
		}
	}

	@keyframes Sneaker_04 {
		from {
			width: 32vw;
			height: 32vw;
			left: 68vw;
			top: 76vw;
			opacity: 100%;
			transform: rotate(-6deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 150px;
			height: 150px;
			left: -350px;
			top: 700px;
			opacity: 100%;
			transform: rotate(2deg);
			visibility: hidden;
		}
	}

	@keyframes corner_01 {
		to {
			width: 38vw;
			height: 38vw;
			left: 85vw;
			top: 30vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes corner_02 {
		to {
			width: 38vw;
			height: 38vw;
			left: 80vw;
			top: 50vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
		}
	}

	@keyframes corner_03 {
		from {
			width: 38vw;
			height: 38vw;
			left: 80vw;
			top: 50vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 180px;
			height: 180px;
			left: -350px;
			top: 600px;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: hidden;
		}
	}

	@keyframes joint_01 {
		to {
			width: 15vw;
			height: 15vw;
			left: 80vw;
			top: 50vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes joint_02 {
		to {
			width: 20vw;
			height: 20vw;
			left: 80vw;
			top: 50vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			filter: invert(60%);
		}
	}

	@keyframes joint_03 {
		to {
			width: 15vw;
			height: 15vw;
			left: 80vw;
			top: 60vw;
			opacity: 70%;
			transform: rotate(0deg);
			filter: blur(0px);
		}
	}

	@keyframes joint_04 {
		from {
			width: 15vw;
			height: 15vw;
			left: 80vw;
			top: 60vw;
			opacity: 70%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 50px;
			height: 50px;
			left: -270px;
			top: 650px;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: hidden;
		}
	}

	@keyframes leftSofa_01 {
		to {
			width: 40vw;
			height: 40vw;
			left: 50vw;
			top: 60vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: visible;
		}
	}

	@keyframes leftSofa_02 {
		to {
			width: 40vw;
			height: 40vw;
			left: 56.5vw;
			top: 54.5vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
		}
	}

	@keyframes leftSofa_03 {
		from {
			width: 40vw;
			height: 40vw;
			left: 56.5vw;
			top: 54.5vw;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: visible;
		}

		to {
			width: 190px;
			height: 190px;
			left: -238px;
			top: 500px;
			opacity: 100%;
			transform: rotate(0deg);
			filter: blur(0px);
			visibility: hidden;
		}
	}
}
