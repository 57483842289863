.shoesQrContainer{
    display: flex;
    padding: 50px 0px;
    background: #E9E9E9;
    flex-direction: row;
    justify-content: center;
    position: relative;
    align-items: center;
}
.qrGroup{
    display: flex;
    gap: 30px;
    justify-content: center;
}
.shoesQrElements{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
}
.shoesQrHeader{
    width: 516px;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    color: #000000;
}
.shoesQrSpan{
    width: 451px;
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
}
.shoesButtons{
    width: 40%;
    background: #FFFFFF;
    border: 2px solid #AAAAAA;
    border-radius: 20px;
    cursor: pointer;
}
.shoesQrShoes{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}
.shoesQrImg{
    position: relative;
}

.shoesButtons img{
    width: 100%;
}

/* Reeponsive Tablet Settings */

.tablet .shoesQrElements{
    position: absolute;
    top: 0px;
    align-items: center;
}

.tablet .shoesQrImg{
    position: absolute;
    bottom: 0px;
    width: 70%;
    height: 50%;
    display: flex;
    justify-content: center;
}

.tablet .shoesQrHeader{
    height: 96px;
    width: 100%;
    font-size: 35px;
}


.tablet .shoesQr{
    position: absolute;
    width: 20%;
    top: 150px !important;
    left: 35%;
    right: auto !important;
}

/* Reeponsive Mobile Settings */

.mobile .shoesQrContainer{
    flex-direction: column;
}

.mobile .shoesQrElements{
    width: 90%;
    align-items: center;
}

.mobile .shoesQrImg{
    width: 70%;
    height: 50%;
    display: flex;
    justify-content: center;
}

.mobile .shoesQrSpan{
    display: none;
}

.mobile .shoesQrPhone{
    width: 70%;

}

.mobile .shoesButtons{
    width: 30%;
}


.mobile .shoesQr{
    position: absolute;
    width: 20%;
    top: 70px !important;
    left: 35%;
    right: auto !important;
}

.mobile .shoesQrHeader{
    width: 100%;
    font-size: 27px;
    text-align: justify;
    line-height: 44px;
}



