.listenToPartnerContainer{
    padding: 50px 0px;
    background: #F2EAE8;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.listenToPartnerContainerNoneVideo{
    padding: 50px 0px;
    background: radial-gradient(pink,#ED401A);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.partner1{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    position: relative;
    width: 100%;
    height: 94%;
    justify-content: center;
}
.mobile .partner1{
    gap: 30px;
}
.partnerHeaders{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 60%;
    text-align: left;
    gap: 100px;
}


.partnerHeader{
    width: 90%;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 38px;
    color: #000000;
}
.partnerSpans{
    width: 65%;
    position: relative;
}
.mobile .partnerSpans{
    width: 90%;
}
.partnerSpan{
    font-style: normal;
    font-weight: 250;
    line-height: 25px;
    text-align: left;
    color: #000000;
    position: relative;
}


#deskopVideoShadow{
    width: 80%;
    opacity: 0.3;
    margin: auto;
}

#mobileVideoShadow{
    display: none;
    width: 90%;
    position: absolute;
    bottom: -16px;
}

.partnerContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    gap: 20px;
    width: 100%;
    position: relative;
    transition: transform 0.5s ease;
}

.partnerCompanyLogo{
    margin-top: 20px;
}

.containerTopLogo{
    position: absolute;
    top: -100px;
    left: 300px;
}

@keyframes slideLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}
@keyframes slideRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

/* Reeponsive Tablet Settings */

.tablet .partnerHeader{
    width: 100%;
    font-size: 22px;
}
.partnerLogo {
    width: 200px !important;
}
.tablet .partnerLogo{
    width: 139px !important;
}
.tablet .partnerSpan{
    font-size: 16px;
    width: 90%;
}

.tablet .containerTopLogo{
    width: 72px;
    top: 0px !important;
    left: 0px !important;
    position: absolute;
}
.tablet .spanBottomLogo{
    width: 37px;
    top: 50px !important;
}
.tablet .partnersPageButtons{
    bottom: 30px;
}

/* Reeponsive Mobile Settings */

.mobile .partnerHeader{
    width: 100%;
    font-size: 22px;
    position: relative;
    line-height: 36px;
    
}
.mobile .partnerLogo{
    width: 200px !important;
    align-self: flex-start;
}
.mobile .partnerSpan{
    font-size: 14px;
    width: 100%;
}

.tablet .partnerVideo{
    width: 90%;
    display: flex;
    justify-content: center;
}

.partnerVideo{
    width: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.partnerVideos{
    width: 80%;
    height: 70vh;
}
.mobile .partnerVideos{
    width: 90% !important;
    align-self: center;
}

.mobile .partnerVideo{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.mobile .containerTopLogo{
    width: 72px;
    top: -78px !important;
    left: 6% !important;
    position: absolute;
}
.mobile .spanBottomLogo{
    width: 37px;
    right: 5% !important;
    top: 90px !important;
}
.mobile .partnersPageButtons{
    bottom: 30px;
}
.mobile .partnerHeaders{
    height: auto;
    width: 94%;
    flex-direction: column-reverse;
    margin-top: 20px;
}

.tablet .partnerHeaders{
    height: auto;
    width: 94%;
    flex-direction: column;
    margin-top: 40px;
}
