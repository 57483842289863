.SugarTeamContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background: white;
    position: relative;
    gap: 60px;
    overflow-x: auto;
    padding-bottom: 60px;
}

.sugarTeamHeader a{
    height: 73px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.sugarTeamLeaders{
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;
}
.sugarTeamLeader{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 22%;
}   
.sugarTeamLeaders img{
    width: 170px;
    background: #FAF1EF;
    border-radius: 100px;
}
.mobile .sugarTeamLeaders img{
    width: 215px;
}
.leaderName{
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 23px;
    text-align: justify;
    letter-spacing: -0.035em;
    color: #ED401A;
}
.mobile .leaderName {
    font-size: 15px;
}

.leaderPosition{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    letter-spacing: -0.035em;
    color: #000000;
}
.leaderComment{
    width: 70%;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #000000;
}

/* Reeponsive Tablet Settings */

.tablet .sugarTeamLeaders{
    gap: 7%;
}

/* Reeponsive Mobile Settings */

.mobile .sugarTeamLeaders{
    height: 64%;
    width: 95%;
    overflow-x: scroll;
    margin-left: 5%;
    padding: 27px 0px;
    gap: 45px;
    justify-content: normal;
}

.mobile .SugarTeamContainer{
    height: 61vh;
    justify-content: flex-start;
}

.mobile .sugarTeamLeader{
    gap: 0px;
    width: auto;
}

.mobile .leaderComment{
    width: auto;
}
