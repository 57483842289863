.productStatisticsContainer{
    padding: 50px 0px;
    display: flex;
    background: white;
    gap: 50px;
    justify-content: space-around;
}
.productStatisticsHeader{
    display: flex;
    height: 140px;
    width: 80%;
    line-height: 57px;  
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    color: #000000;
}
.productStatisticsSpans{
    width: 60%;
    display: flex;
    height: 130px;
    font-style: normal;
    font-weight: 250;
    font-size: 20px;
    line-height: 26px;
    text-align: justify;
    text-transform: capitalize;
    color: #000000;
    gap: 20px;
    flex-direction: column;
}
.productStatisticsSpanElements{
    display: flex;
    flex-direction: column;
    width: 60%;
}
.productStatisticsImage{
    width: 50%;
}

.productStatisticsSpanElementsDiv{
    display: flex; 
    width: 70%;
    position: relative;
}

@media screen and (max-width: 1200px) {

    .productStatisticsContainer{
        flex-direction: column;
        justify-content: normal;
        align-items: center;
    }
    .productStatisticsHeader{
        height: 120px;
        font-size: 29px;
        position: relative;
    }
    .productStatisticsSpans{
        font-size: 13px;
        gap: 10px;
        position: relative;
    }
    .productStatisticsImage img{
        width: 300px;
    }
}

/* Reeponsive Mobile Settings */

.mobile .productStatisticsContainer{
    flex-direction: column;
    justify-content: normal;
    align-items: center;
}
.mobile .productStatisticsSpanElements{
    align-items: center;
    width: 100%;
}
.mobile .productStatisticsHeaders{
    margin-top: 20px;
}
.mobile .productStatisticsHeader{
    width: 100%;
    height: auto;
    font-size: 29px;
    text-align: justify;
    line-height: 35px;
}
.mobile .productStatisticsSpans{
    line-height: 22px;
    font-size: 13px;
    gap: 10px;
    position: relative;
    margin-top: 20px;
    width: 88%;
}
.mobile .productStatisticsImage img{
    width: 300px;
}
.mobile .productStatisticsSpanElementsDiv{
    flex-direction: column;
    gap: 50px;
}
