.contactInputContainer {
	padding: 50px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 60px;
}
.contactInputs {
	width: 50%;
	background: rgba(255, 255, 255, 0.4);
	border: 2px solid rgba(255, 255, 255, 0.5);
	box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.25), inset 0px 0px 10px rgba(255, 255, 255, 0.7);
	backdrop-filter: blur(15px);
	border-radius: 42px;
	display: flex;
    left: 5%;
    position: relative;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	z-index: 999;
}
.contactInputInputs {
	display: flex;
	gap: 20px;
	flex-direction: column;
	margin-top: 10px;
}
.contactInputHeaders {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.contactInputButtons {
	margin-bottom: 20px;
}
.tablet #contactFormElement {
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.mobile #contactFormElement {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.contactInputHeader {
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 60px;
	display: flex;
	align-items: center;
	text-align: justify;
	letter-spacing: -0.02666em;
	background: linear-gradient(135deg, #ffa895 15.1%, #ff2e00 67.71%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
}
.contactInputSpan {
	width: 90%;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	display: flex;
	align-items: center;
	text-align: center;
}
.nameEmailInputs {
	display: flex;
	gap: 30px;
}
.nameInputs {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.nameInputSpan {
	height: 19px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 66px;
	display: flex;
	align-items: center;
	color: #252f40;
}
.emailInputs {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.emailInputSpan {
	width: 48px;
	height: 19px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 66px;
	display: flex;
	align-items: center;
	color: #252f40;
}
.nameEmailInput {
	height: 4.5vh;
	width: 40%;
	min-width: 200px;
	max-width: 300px;
	border-radius: 8px;
	background: #ffffff;
	border: 1px solid #d2d6da;
}
.fullnameInput {
	height: 4.5vh;
	width: calc(99% - 30px);
	min-width: 200px;
	max-width: 500px;
	border-radius: 8px;
	background: #ffffff;
	padding: 5px 15px;
	border: 1px solid #d2d6da;
}
.descriptionInputs {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.descriptionInput {
	height: 90px;
	min-width: 200px;
	background: #ffffff;
	border: 1px solid #d2d6da;
	border-radius: 8px;
	padding: 8px;
}
textarea {
	resize: none;
}
.sendMessageButton {
	height: 40px;
	width: 152px;
	border: none;
	background: linear-gradient(135deg, #ffa895 15.1%, #ff2e00 67.71%);
	box-shadow: 0px 4px 7px -1px rgba(0, 0, 0, 0.11), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
	border-radius: 51px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.sendMessageButton a {
	height: 16px;
	width: 112px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	display: flex;
	align-items: center;
	text-align: justify;
	color: #ffffff;
}
.descriptionInputSpan {
	height: 19px;
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 66px;
	display: flex;
	align-items: center;
	color: #252f40;
}
.nameEmailInput::placeholder {
	position: relative;
	left: 5px;
}
.descriptionInput::placeholder {
	position: relative;
	left: 5px;
}

.contactComponentDiv {
	display: none;
	z-index: 999;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	position: absolute;
}

.contactOverlay {
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.tablet .contactInputs {
	width: auto;
}

/* Reeponsive Mobile Settings */

.mobile .contactInputs {
	width: 90%;
}
.mobile .contactInputSpan {
	width: 300px;
	height: 120px;
	width: 90%;
}
.mobile .nameEmailInputs {
	flex-direction: column;
	align-items: center;
}
.mobile .descriptionInput {
	width: 295px;
}
.mobile .nameInputs,
.mobile .emailInputs {
	width: 100%;
}

.mobile .nameEmailInput {
	width: auto;
}
