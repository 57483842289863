.tryShoesContainer{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.shoeShadow{
    position: absolute;
    top: 776px;
    width: 813px;
    z-index: -1;
    opacity: 0.9;
}
