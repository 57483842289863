.productsCustomerContainer{
    display: flex;
    justify-content: space-evenly;
    gap: 50px;
    padding: 20px 0px;
    background: #ED401A;
    transform: matrix(1, 0, 0, -1, 0, 0);
}
.productsCustomerContainer img{
    transform: scaleY(-1);
    width: 14%;
}

/* Reeponsive Mobile Settings */

.mobile .productsCustomerContainer{
    gap: 20px;
}
.mobile .productsCustomerContainer img{
    width: 20%;
}