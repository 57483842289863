.jointAboutContainer{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.jointAboutElements{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    justify-content: space-evenly;
    gap: 50px;
    overflow: hidden;
}
.jointAboutHeader{
    width: 65%;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    text-align: center;
    color: #000000;
}
.jointAboutSpan{
    width: 60%;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
}
.jointAboutBubble{
    position: absolute;
    right: 0px;
    z-index: -1;
}
.jointShadow{
    position: absolute;
    bottom: 40px;
    width: 100%;
    z-index: -1;
    opacity: 0.5;
}
.jointAboutImage{
    width: 60%;
    height: 400px;
    margin: auto;
}

.mobile .jointAboutImage{
    width: 90%;
    margin: auto;
    height: 300px;

}
.tablet .jointAboutImage{
    width: 80%;
}


/* Reeponsive Mobile Settings */

.mobile .jointAboutContainer{
    height: 75vh;
}
.mobile .jointAboutHeader{
    font-size: 29px;
    line-height: 45px;
    width: 90%;
}
.mobile .jointAboutSpan{
    width: 80%;
    font-size: 14px;
    line-height: 20px;
}


.mobile .jointAboutElements{
    justify-content: flex-start;
    gap: 30px;
    height: 88%;
}

.mobile .jointAboutBubble{
    display: none;
}

/* Reeponsive Tablet Settings */

.tablet .jointAboutBubble{
    display: none;
}

.tablet .jointAboutContainer{
    height: 70vh;
}
.tablet .jointAboutHeader{
    font-size: 29px;
    line-height: 45px;
}
.tablet .jointAboutSpan{
    width: 80%;
    font-size: 13px;
    line-height: 24px;
}

.tablet .jointAboutElements{
    justify-content: space-evenly;
}

