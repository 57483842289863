.tryWatchContainer{
    padding: 50px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.tryWatchElements{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.tryWatchImageDiv{
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
}

.tryWatchImage{
    width: 60%;
}

.watchAboutHeader{
    width: 90%;
    font-style: normal;
    font-weight: 700;
    font-size: 29px;
    line-height: 56px;
    text-align: center;
    color: #000000;
}

/* Reeponsive Mobile Settings */

.mobile .tryWatchContainer{
    height:79vh;
}

.watchVideoFrame{
    width: 65%;
    height: 71%;
}

.mobile .watchVideoFrame{
    width: 90%;
    height: 250px;
}