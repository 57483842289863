.metaReportsContainer{
    padding: 50px 0px;
    display: flex;
    gap: 50px;
    position: relative;
    justify-content: center;
    align-items: center;
}
.metaReportsSpans{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 40%;
}
.metaReportsSpan{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
}
.metaReportsList{
    width: 50%;
    font-size: 18px;
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.metaReportsImage{
    width: 500px;
}

/* Reeponsive Tablet Settings */

.tablet .metaReportsImg{
    position: absolute;
    bottom: 100px;
}

.tablet .metaReportsImage{
    width: 300px;
}

.tablet .metaReportsSpans{
    position: absolute;
    top: 10px;
    right: auto;
    align-items: center;
}


.tablet .metaReportsSpan{
    width: 80%;
    font-size: 16px;
    text-align: justify;
}

/* Reeponsive Mobile Settings */

.mobile .metaReportsContainer{
    flex-direction: column-reverse;
}

.mobile .metaReportsImage{
    width: 300px;
}

.mobile .metaReportsSpans{
    width: 90%;
    align-items: center;
    margin-top: 20px;
    gap: 23px;
}


.mobile .metaReportsSpan{
    width: 80%;
    font-size: 14px;
    text-align: justify;
    line-height: 25px;
}

.mobile .metaReportsList {
    font-size: 15px;
}